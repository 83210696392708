import { FC, useState, useEffect, useRef, SetStateAction, Dispatch, ReactNode } from 'react';

import { useBoundingBox } from '@/hooks';

import { ExpandIcon } from '@/ui';

import styles from './Collapsible.module.scss';

type Props = {
    children?: ReactNode;
    title: ReactNode;
    expanded?: boolean;
    isChildrenFocusable?: boolean | undefined;
    setIsChildrenFocusable?: Dispatch<SetStateAction<boolean>>;
    heading?: 1 | 2 | 3 | 4 | 5 | 6;
};

const Collapsible: FC<Props> = ({
    children,
    title,
    expanded = false,
    isChildrenFocusable,
    setIsChildrenFocusable,
    heading = 3,
}) => {
    const [isExpanded, setIsExpanded] = useState(expanded);
    const [collapsibleHeight, setCollapsibleHeight] = useState(0);
    const elRef = useRef<HTMLDivElement>(null);
    const box = useBoundingBox<HTMLDivElement>({ ref: elRef });
    const currentHeight = isExpanded ? collapsibleHeight : 0;

    const HeadingTag: keyof JSX.IntrinsicElements = `h${heading}`;

    useEffect(() => {
        if (elRef.current) {
            setCollapsibleHeight(elRef.current.clientHeight);
        }
    }, [box]);

    const toggleIsExpanded = (): void => {
        if (elRef.current) {
            setIsExpanded(!isExpanded);
            setCollapsibleHeight(elRef.current.clientHeight);
            if (
                typeof setIsChildrenFocusable === 'function' &&
                typeof isChildrenFocusable !== 'undefined'
            ) {
                setIsChildrenFocusable(!isChildrenFocusable);
            }
        }
    };

    return (
        <div>
            <div
                className={styles['titleWrapper']}
                role="button"
                tabIndex={0}
                onClick={toggleIsExpanded}
                onKeyDown={(event): void => {
                    if (event.key === ' ') {
                        event.preventDefault();
                        toggleIsExpanded();
                    }
                }}
            >
                <HeadingTag className={styles['title']}>{title}</HeadingTag>
                <ExpandIcon expanded={isExpanded} />
            </div>
            <div className={styles['content']} style={{ height: currentHeight + 'px' }}>
                <div ref={elRef}>{children}</div>
            </div>
        </div>
    );
};

export default Collapsible;
