import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import Cookies from 'js-cookie';

import { showCartVar, CartItem } from '@/apollo/reactiveVars';
import { ReactiveVar } from '@apollo/client';

import { Button } from '@/ui';
import { Close } from '@/components/Icons';

import styles from './RemoveCartItem.module.scss';

type Props = {
    index: number;
    cartItems: CartItem[];
    cartItemsVar: ReactiveVar<CartItem[]>;
    // For GTAG
    sku: string;
    name: string;
    productType: string;
    price: number;
    size: string;
    quantity: number;
};

const RemoveCartItem: FC<Props> = ({
    index,
    cartItems,
    cartItemsVar,
    sku,
    name,
    productType,
    price,
    size,
    quantity,
}) => {
    const showCart = useReactiveVar(showCartVar);

    const removeItem = (): void => {
        const updatedCart = [...cartItems];
        updatedCart.splice(index, 1);
        if (
            process.env.NODE_ENV === 'production' &&
            Cookies.get('COOKIE_CONSENT_FULL') &&
            typeof window.gtag !== 'undefined'
        ) {
            gtag('event', 'remove_from_cart', {
                currency: 'EUR',
                items: [
                    {
                        id: sku,
                        name: name,
                        category: productType,
                        price: price,
                        quantity: quantity,
                        variant: size,
                    },
                ],
                value: price * quantity,
            });
        }
        cartItemsVar(updatedCart);

        const cookieData = JSON.stringify(updatedCart);
        Cookies.set('CART_DATA', cookieData, { expires: 30, sameSite: 'none', secure: true });
    };
    return (
        <Button className={styles['button']} onClick={removeItem} focusable={showCart}>
            <Close width={16} height={16} />
        </Button>
    );
};

export default RemoveCartItem;
