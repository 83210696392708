import { asTypename } from '@/crystallize/helpers';

import { ProductFragment } from '@/catalogueFragments';
import { CartItem } from '@/apollo/reactiveVars';

export default function getShoppingCartProductData(
    cartItem: CartItem,
    shoppingCartItemData: ProductFragment[]
): ProductFragment | null {
    const product = shoppingCartItemData.find(p => {
        return p?.id === cartItem.productId;
    });
    return asTypename(product, 'Product');
}
