import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const Marker: FC<Props> = ({ className, width = 50, height = 50 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        enableBackground="new 0 0 91 91"
        viewBox="0 0 91 91"
    >
        <g>
            <path d="M66.9,41.8c0-11.3-9.1-20.4-20.4-20.4c-11.3,0-20.4,9.1-20.4,20.4c0,11.3,20.4,32.4,20.4,32.4S66.9,53.1,66.9,41.8z    M37,41.4c0-5.2,4.3-9.5,9.5-9.5c5.2,0,9.5,4.2,9.5,9.5c0,5.2-4.2,9.5-9.5,9.5C41.3,50.9,37,46.6,37,41.4z" />
        </g>
    </svg>
);

export default Marker;
