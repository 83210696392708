import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const ChevronLeftRoundOutlined: FC<Props> = ({ className, width = 50, height = 50 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
    >
        <g>
            <path
                d="M25,1C11.767,1,1,11.767,1,25s10.767,24,24,24s24-10.767,24-24S38.233,1,25,1z M25,47C12.869,47,3,37.131,3,25
                S12.869,3,25,3s22,9.869,22,22S37.131,47,25,47z"
            />
            <polygon points="29.293,10.293 14.586,25 29.293,39.707 30.707,38.293 17.414,25 30.707,11.707 	" />
        </g>
    </svg>
);

export default ChevronLeftRoundOutlined;
