export default function toggleBodyFixedClass(state: boolean): void {
    const body = document?.body;

    if (body) {
        if (state === true) {
            body.classList.add('fixed');
            return;
        }

        body.classList.remove('fixed');
    }
}
