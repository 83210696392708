import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const OmnivaLogo: FC<Props> = ({ className, width = 100, height = 25 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        x="0px"
        y="0px"
        viewBox="0 0 808.1 201.5"
    >
        <style type="text/css">
            {
                '.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#EA5B08;} .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#E9470A;} .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#E6035C;} .st3{fill-rule:evenodd;clip-rule:evenodd;fill:#E4022C;}}'
            }
        </style>
        <path
            id="path2"
            className="st0"
            d="M28.2,0.1c0.9-0.3,1.6,0.6,2.3,1.1c9.7,9,22.8,14.1,36,14.3c13.8,0.3,27.6-5,37.7-14.3
	c0.6-0.5,1.4-1.4,2.3-1c0.9,0.6,1.6,1.4,2.3,2.1c8.1,8.1,16.1,16.2,24.2,24.3c0.5,0.5,1.2,1.2,0.9,2c-0.9,1.3-2.2,2.2-3.4,3.3
	c-10.6,9.5-23.3,16.5-36.9,20.4c-17.8,5.1-37,4.9-54.6-0.6C25.1,47.4,12.3,39.9,1.7,29.9c-0.5-0.6-1.4-1.2-1.2-2.1
	c0.6-1.1,1.5-1.8,2.4-2.7C11,17.1,19,8.9,27.1,0.9C27.4,0.6,27.8,0.3,28.2,0.1z"
        />
        <path
            id="path4"
            className="st1"
            d="M172.9,0.2c0.7-0.6,1.5,0.1,2,0.6c8.6,8.6,17.2,17.2,25.8,25.8c0.5,0.5,1.1,1.1,0.9,1.9
	c-0.4,0.7-1,1.3-1.6,2c-8.9,9.9-13.9,23.2-13.8,36.6c0,13.5,5.3,26.9,14.5,36.8c0.8,0.7,1.4,1.9,0.4,2.7
	c-8.7,8.8-17.5,17.5-26.2,26.2c-0.7,0.9-2,0.9-2.6,0c-7.5-7.8-13.7-16.9-18.2-26.8c-6.6-14.6-9.3-30.8-8.1-46.7
	C147.7,37.2,157.3,15.8,172.9,0.2z"
        />
        <path
            id="path6"
            className="st2"
            d="M27,67.6c0.7-0.7,1.7-0.2,2.2,0.5c7.9,7.9,15.7,15.7,23.6,23.6c1,1.1,2.2,2,3,3.2
	c0.4,1-0.5,1.7-1.1,2.3c-9.2,10-14.4,23.6-14.3,37.2c0.1,13.3,5.3,26.6,14.3,36.3c0.4,0.6,1.2,1.1,1.1,2c0,0.6-0.6,0.9-0.9,1.4
	c-8.6,8.6-17.1,17.2-25.8,25.8c-0.5,0.5-1.2,1-1.9,0.6c-1-0.7-1.8-1.8-2.7-2.7c-8.9-9.8-15.7-21.4-19.8-34
	c-5.8-17.7-6.3-37.1-1.4-55C7.5,93.4,15.7,79,27,67.6L27,67.6z"
        />
        <path
            className="st3"
            d="M95.7,145.7c1-0.6,1.8,0.5,2.5,1c9.8,9.1,23.2,14.3,36.5,14.2c13.6,0.1,27.2-5.1,37.2-14.4
	c0.7-0.8,1.9-1.5,2.7-0.4c7.9,7.8,15.7,15.7,23.6,23.6c1.1,1.1,2.3,2.1,3.2,3.4c0.4,0.7-0.1,1.4-0.6,1.9
	c-11.5,11.1-25.7,19.2-41.1,23.3c-17.3,4.6-35.9,4.3-53-1c-14.2-4.4-27.3-12.2-37.9-22.5c-0.9-0.6-0.6-1.8,0.1-2.4
	c8.1-8.1,16.2-16.2,24.2-24.3C94.1,147.3,94.8,146.4,95.7,145.7L95.7,145.7z"
        />
        <path
            className="st3"
            d="M614.3,31.7c6.8-1.8,14.3,3.3,15.1,10.2c1.1,6.4-3.5,13-9.9,14.1c-6.7,1.5-13.8-3.5-14.7-10.3
	C603.7,39.5,608.1,33,614.3,31.7L614.3,31.7z"
        />
        <path
            className="st3"
            d="M339.8,93.5c-4.1-10.4-12.3-19.2-22.5-23.8c-8.4-3.9-18-5-27.1-3.2c-11.5,2.2-22,9.4-28.4,19.3
	c-6.7,10.3-8.8,23.5-5.6,35.3c2.7,10.2,9.2,19.4,18,25.2c9.6,6.5,21.9,9,33.3,6.6c12.6-2.4,23.8-10.7,29.9-22
	C343.7,119.6,344.6,105.5,339.8,93.5z M310.4,131.9c-10.9,6-25.9,2.1-32.5-8.4c-7-10.1-4.8-25.1,4.8-32.9c3.1-2.6,6.8-4.4,10.7-5.3
	c11.9-2.9,25.1,4.4,29,16C326.7,112.6,321.2,126.5,310.4,131.9z"
        />
        <path
            className="st3"
            d="M389.6,66.7c12.3-1.3,26-2.7,36.8,4.4c13.4-4.1,27.5-6.5,41.6-5c6.2,0.7,12.9,2.4,17.1,7.4
	c4.1,4.7,4.9,11.2,4.9,17.2c0,19.2,0,38.4,0,57.5c0.1,1.5-0.2,3.5-2.2,3.4c-5.8,0.1-11.6,0.2-17.4-0.1c-1.7-0.1-1.9-2-1.8-3.4
	c0-18.4,0-36.8,0-55.2c0-3.2-0.4-6.7-2.5-9.3c-2.1-2.5-5.4-3.2-8.5-3.3c-7.2-0.2-14.4,1.1-21.4,2.6c-0.1,22,0,44-0.1,65.9
	c0.2,1.6-0.9,3-2.6,2.7c-6.1-0.1-12.2,0.5-18.3-0.4c-0.2-1.3-0.4-2.6-0.4-3.9c0-18.1,0-36.3,0-54.4c0-3.8-0.5-8.4-4-10.8
	c-3.6-2.3-8.2-1.9-12.3-1.7c-5.4,0.3-10.8,1.4-16.1,2.4c-0.7,22.7,0.2,45.4-0.4,68.1c-2.9,1.1-6.1,0.6-9.2,0.7
	c-3.4-0.1-6.9,0.2-10.3-0.2c-1.7-0.1-1.9-2-1.8-3.3c0-22.9,0-45.8,0-68.7c-0.1-3.4,2.3-6.3,5.4-7.4C373.7,69,381.7,67.7,389.6,66.7
	L389.6,66.7z"
        />
        <path
            className="st3"
            d="M526.6,68.8c11.1-2.4,22.6-3.7,34-3c5.9,0.4,12,1.3,17,4.7c3.8,2.5,6.3,6.7,7.3,11.1c1,4.3,0.8,8.7,0.9,13
	c0,15.7,0,31.4,0,47.1c0,3.2,0.2,6.4-0.4,9.5c-3.4,0.7-6.9,0.4-10.3,0.4c-3.4-0.1-6.9,0.3-10.3-0.5c-0.3-1.3-0.5-2.6-0.5-3.8
	c0-18.1,0-36.2,0-54.4c0-3.2-0.2-6.6-2.3-9.2c-2-2.6-5.5-3.3-8.6-3.4c-6.3-0.1-12.5,0.8-18.6,2.1c-0.9,0.3-2.8,0.3-2.5,1.8
	c0,19.7,0,39.4,0,59.1c-0.1,2.5,0.2,5-0.3,7.4c-1.3,1.3-3.3,0.8-4.9,0.9c-4.8-0.1-9.5,0.2-14.3-0.2c-1.7-0.1-2-2-1.9-3.4
	c0-22.6,0-45.3,0-67.9c-0.1-2.8,1-5.7,3.4-7.2C518,70.8,522.4,69.9,526.6,68.8L526.6,68.8z"
        />
        <path
            className="st3"
            d="M802.5,73.1c-5.5-5.2-13.4-6.6-20.7-7.2c-12.9-0.9-25.9,0.6-38.4,3.9l0,0c-1.8,0.5-3.7,0.9-5.3,2.1
	c-1.3,1.4-0.3,3.3-0.1,4.9c0.8,2.8,0.9,5.9,2.3,8.5c1.5,0.4,3-0.2,4.5-0.5c10.8-2.9,22.1-4.8,33.3-3.4c2.9,0.5,6.4,1.5,7.6,4.5
	c1.3,3.5,0.7,7.4,0.8,11.1c-9.1,0.9-18.2,1.7-27.2,2.6c-7.1,1-14.6,2.8-20,7.9c-5.2,4.9-7,12.3-6.9,19.2c-0.1,6.9,1.7,14.3,7,19.1
	c5.9,5.6,14.4,7.1,22.3,7.7c11.8,0.7,23.7,0.3,35.2-2.9c3.2-0.9,6.7-2.1,8.8-4.9c2.1-3,2.4-6.8,2.5-10.3c0-14.9,0-29.8,0-44.8
	C808.1,84.5,807.2,77.6,802.5,73.1z M786.5,137.7c-7.1,2.1-14.7,2.8-22.1,1.5c-4-0.7-8-3.2-9.3-7.3c-1.3-4.3-1.2-9.3,1-13.3
	c2.3-3.9,7.1-5,11.3-5.5c6.4-0.7,12.8-1.5,19.3-2.1C786.6,119.9,786.8,128.8,786.5,137.7z"
        />
        <path
            className="st3"
            d="M644.9,68c5.4-0.6,10.9-0.2,16.4-0.3c1.8,0,4.4-0.1,4.8,2.2c6,22.2,11.5,44.4,17.5,66.6c1-0.2,1.6-0.7,1.7-1.7
	c4.9-21,9.8-42,14.7-63c0.4-1.3,0.6-2.8,1.7-3.6c2.1-0.7,4.4-0.4,6.6-0.5c4.2,0.1,8.4-0.2,12.6,0.2c2.1,0.1,2.3,2.7,1.7,4.2
	c-7.4,25.4-14.8,50.8-22.2,76.2c-0.4,1.6-1.3,3.6-3.3,3.4c-8.8,0.2-17.6,0.1-26.3,0c-1.9,0.1-3-1.7-3.5-3.3
	c-7.1-23.9-14.3-47.8-21.4-71.8C645.2,73.8,643.5,70.9,644.9,68L644.9,68z"
        />
        <path
            className="st3"
            d="M606.8,70.1c1.2-1.4,3.2-0.9,4.8-1c5.2,0.2,10.5-0.4,15.7,0.4c0.3,1.5,0.5,2.9,0.5,4.4c-0.1,25,0,50.1,0,75.1
	c0.3,2-1.6,3-3.3,2.7c-5.8-0.2-11.7,0.5-17.5-0.5c-0.3-1.3-0.5-2.6-0.5-3.8c0-23.7,0-47.4,0-71.1C606.5,74.2,606.2,72.1,606.8,70.1
	L606.8,70.1z"
        />
    </svg>
);

export default OmnivaLogo;
