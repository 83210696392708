import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const NumberTwo: FC<Props> = ({ className, width = 640, height = 640 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640"
    >
        <defs>
            <path
                d="M0 320C0 496 144 640 320 640C496 640 640 496 640 320C640 144 496 0 320 0C144 0 0 144 0 320ZM412.8 425.6L412.8 457.6C297.6 457.6 233.6 457.6 220.8 457.6C220.8 332.8 379.2 336 379.2 246.4C379.2 238.4 372.8 193.6 321.6 193.6C267.2 193.6 264 257.6 264 267.2C260.48 267.2 232.32 267.2 228.8 267.2C228.8 235.2 236.8 163.2 323.2 163.2C409.6 163.2 416 233.6 416 248C416 348.8 270.4 361.6 262.4 427.2C272.43 427.2 322.56 427.2 412.8 427.2L412.8 425.6Z"
                id="asM8i3VFy"
            ></path>
        </defs>
        <g>
            <g>
                <use xlinkHref="#asM8i3VFy" opacity="1" fill="currentColor" fillOpacity="1"></use>
                <g>
                    <use
                        xlinkHref="#asM8i3VFy"
                        opacity="1"
                        fillOpacity="0"
                        stroke="currentColor"
                        strokeWidth="1"
                        strokeOpacity="0"
                    ></use>
                </g>
            </g>
        </g>
    </svg>
);

export default NumberTwo;
