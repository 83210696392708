import { FC, useEffect } from 'react';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { AppProps } from 'next/app';
import { IntlProvider } from 'react-intl';
import { ApolloProvider } from '@apollo/client';
import Cookies from 'js-cookie';

import * as gtag from '@/services/gtag';
import { useApollo } from '@/apollo/client';
import { DOMAIN_URL, REFERRAL_QUERY_PARAM } from '@/data/constants';

import { Layout as DefaultLayout, LayoutProps } from '@/components/Layout';

import '@/styles/global.scss';

const CookieConsent = dynamic(() => import('@/components/CookieConsent'), {
    ssr: false,
});
interface Props extends AppProps {
    Component: NextPage & { layout?: FC<LayoutProps>; layoutProps?: LayoutProps };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageProps: Record<string, any>;
    err: unknown;
}

const handleRouteChange = (url: string): void => {
    if (typeof window.gtag !== 'undefined') {
        gtag.pageview(url);
    }
};

export const App = ({ Component, pageProps, err }: Props): JSX.Element => {
    const Layout = Component.layout || DefaultLayout;
    const layoutProps = Component.layoutProps;
    const { query, locale, events, locales, defaultLocale, asPath } = useRouter();
    const client = useApollo(pageProps);

    useEffect(() => {
        if (query) {
            const referralQueryParam = query[REFERRAL_QUERY_PARAM];

            if (referralQueryParam) {
                sessionStorage.setItem(REFERRAL_QUERY_PARAM, referralQueryParam.toString());
            }
        }
    }, [query]);

    useEffect(() => {
        events.on('routeChangeComplete', handleRouteChange);
        return () => {
            events.off('routeChangeComplete', handleRouteChange);
        };
    }, [events]);

    return (
        <IntlProvider
            locale={locale as Locale}
            defaultLocale="lt"
            messages={pageProps?.intlMessages || {}}
        >
            <ApolloProvider client={client}>
                <Head>
                    <link rel="alternate" hrefLang="x-default" href={`${DOMAIN_URL}${asPath}`} />
                    {(locales || []).map(loc => {
                        const prefix = loc === defaultLocale ? '' : `/${loc}`;
                        return (
                            <link
                                key={loc}
                                rel="alternate"
                                hrefLang={loc}
                                href={`${DOMAIN_URL}${prefix}${asPath}`}
                            />
                        );
                    })}
                </Head>
                {process.env.NODE_ENV === 'production' && (
                    <>
                        <Script
                            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`}
                            strategy="afterInteractive"
                        />
                        <Script id="google-analytics" strategy="afterInteractive">
                            {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){window.dataLayer.push(arguments);}

                                gtag('consent', 'default', {
                                    'ad_storage': 'denied',
                                    'analytics_storage': 'denied',
                                });

                                gtag('js', new Date());
                                gtag('config', '${process.env.NEXT_PUBLIC_GA_TRACKING_ID}');
                                gtag('config', '${process.env.NEXT_PUBLIC_GA_ADS_ID}');
                            `}
                        </Script>
                        {Cookies.get('COOKIE_CONSENT_FULL') && (
                            <Script id="google-analytics-consent" strategy="afterInteractive">
                                {`
                                    gtag('consent', 'update', {
                                        'ad_storage': 'granted',
                                        'analytics_storage': 'granted'
                                    });
                                `}
                            </Script>
                        )}
                    </>
                )}
                <div id="modal" className="Modal" />
                <Layout {...layoutProps}>
                    {/* @ts-ignore Type '{ err: unknown; }' is not assignable to type 'IntrinsicAttributes'. */}
                    <Component {...pageProps} err={err} />
                </Layout>
                <CookieConsent />
            </ApolloProvider>
        </IntlProvider>
    );
};

export default App;
