import { FC, useEffect, useRef, ReactNode, Suspense } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useReactiveVar } from '@apollo/client';
import classnames from 'classnames';

import { useKeyDown } from '@/hooks';
import { showNavVar } from '@/apollo/reactiveVars';

import { Container, Spinner } from '@/ui';
import { Nav } from '@/components/Nav';
import { Footer } from '@/components/Footer';

import styles from './Layout.module.scss';

const ShoppingCart = dynamic(() => import('@/components/ShoppingCart/ShoppingCart'), {
    suspense: true,
    ssr: false,
});

export type LayoutProps = {
    children?: ReactNode;
    noNav?: boolean;
    noGutter?: boolean;
};

const Layout: FC<LayoutProps> = ({ children, noNav = false, noGutter = false }) => {
    const showNav = useReactiveVar(showNavVar);
    const router = useRouter();
    const elRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        elRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, [router.pathname]);

    useKeyDown('Escape', () => {
        showNavVar(false);
    });

    return (
        <div className={styles['layout']}>
            {!noNav && (
                <header>
                    <Nav />
                    <div
                        className={classnames(styles['overlay'], {
                            [styles['expanded']]: showNav,
                        })}
                        role="presentation"
                        onClick={(): void => {
                            showNavVar(false);
                        }}
                    ></div>
                    <Suspense fallback={<Spinner />}>
                        <ShoppingCart />
                    </Suspense>
                </header>
            )}
            <Container<HTMLDivElement>
                elRef={elRef}
                className={classnames(styles['wrapper'], {
                    [styles['noNav']]: noNav,
                })}
                noGutter={noGutter}
                fullWidth
            >
                {children}
            </Container>
            <Footer />
        </div>
    );
};

export default Layout;
