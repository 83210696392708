import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const ChevronDown: FC<Props> = ({ className, width = 14, height = 14 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        viewBox="0 0 407.437 407.437"
    >
        <path d="m386.258 91.567-182.54 181.945L21.179 91.567 0 112.815 203.718 315.87l203.719-203.055z" />
    </svg>
);

export default ChevronDown;
