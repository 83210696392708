import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import classnames from 'classnames';

import { showNavVar } from '@/apollo/reactiveVars';

import { ShoppingCartIcon } from '@/components/ShoppingCart';
import { BCLogo } from '@/components/Icons';
import { Container } from '@/ui';

import NavIcon from './NavIcon';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

import styles from './Nav.module.scss';
import { LanguageSwitcher } from '../LanguageSwitcher';

const NavigationBar: FC = () => {
    const showNav = useReactiveVar(showNavVar);

    return (
        <nav
            className={classnames(styles['nav'], {
                [styles['expanded']]: showNav,
            })}
        >
            <div className={styles['divider']} />
            <Container className={styles['content']}>
                <div className={styles['navIconWrapper']}>
                    <NavIcon />
                </div>
                <div className={styles['logoIconWrapper']}>
                    <BCLogo className={styles['logo']} width={21} height={30} />
                </div>
                <DesktopNav />
                <div className={styles['innerWrapper']}>
                    <div className={styles['languageSwitcherWrapper']}>
                        <LanguageSwitcher />
                    </div>
                    <div className={styles['shoppingCartIconWrapper']}>
                        <ShoppingCartIcon interactive />
                    </div>
                </div>
            </Container>
            <MobileNav />
        </nav>
    );
};

export default NavigationBar;
