import { ApolloLink, HttpLink } from '@apollo/client';

import { restLink } from './rest-link';

const catalogueLink = new HttpLink({
    uri: `https://api.crystallize.com/${process.env.NEXT_PUBLIC_CRYSTALLIZE_TENANT_IDENTIFIER}/catalogue`,
    credentials: 'same-origin',
});

const searchLink = new HttpLink({
    uri: `https://api.crystallize.com/${process.env.NEXT_PUBLIC_CRYSTALLIZE_TENANT_IDENTIFIER}/search`,
    credentials: 'same-origin',
});

const splitLink = ApolloLink.split(
    operation => operation.getContext().clientName === 'search',
    searchLink,
    catalogueLink
);

export const link = ApolloLink.from([restLink, splitLink]);
