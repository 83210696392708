import { FC, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useReactiveVar } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';
import classnames from 'classnames';

import { cartItemsVar, showCartVar } from '@/apollo/reactiveVars';
import { FREE_SHIPPING_AMOUNT } from '@/data/constants';
import { useKeyDown } from '@/hooks';

import {
    ShoppingCartIcon,
    ShoppingCartLabels,
    ShoppingCartItemsWrapper,
} from '@/components/ShoppingCart';
import { Close } from '@/components/Icons';
import { Button } from '@/ui';

import { toggleBodyFixedClass } from '@/helpers';

import styles from './ShoppingCart.module.scss';

const ShoppingCart: FC = () => {
    const [totalCartItemPrice, setTotalCartItemPrice] = useState(0);
    const [amountUntilFreeShipping, setAmountUntilFreeShipping] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const router = useRouter();
    const showCart = useReactiveVar(showCartVar);
    const cartItems = useReactiveVar(cartItemsVar);

    useEffect(() => {
        toggleBodyFixedClass(showCart);

        return () => toggleBodyFixedClass(false);
    }, [showCart]);

    useEffect(() => {
        if (cartItems.length === 0) {
            const cookieData = Cookies.get('CART_DATA');
            if (cookieData) {
                cartItemsVar(JSON.parse(cookieData));
            }
        }
    }, [cartItems.length]);

    useEffect(() => {
        return () => {
            showCartVar(false);
        };
    }, []);

    useKeyDown('Escape', () => {
        showCartVar(false);
    });

    const proceedTocheckout = (): void => {
        setIsLoading(true);

        router.push(`/checkout/`);
        router.events.off('routeChangeComplete', () => {
            setIsLoading(false);
            showCartVar(false);
        });
    };

    return (
        <div
            className={classnames(styles['shoppingCart'], {
                [styles['expanded']]: showCart,
            })}
        >
            <div
                className={styles['overlay']}
                role="presentation"
                onClick={(): boolean => showCartVar(false)}
            ></div>
            <div>
                <div className={styles['content']}>
                    <div className={styles['headerWrapper']}>
                        <div className={styles['header']}>
                            <Button
                                className={styles['close']}
                                onClick={(): boolean => showCartVar(false)}
                                focusable={showCart}
                            >
                                <Close width={22} height={22} />
                            </Button>
                            {cartItems.length === 0 && (
                                <div className={styles['headerInfo']}>
                                    <FormattedMessage
                                        id="components.shoppingCart.shoppingCart.freeShippingFrom"
                                        defaultMessage="Nemokamas pristatymas nuo {FREE_SHIPPING_AMOUNT} €"
                                        values={{ FREE_SHIPPING_AMOUNT }}
                                    />
                                </div>
                            )}
                            {cartItems.length !== 0 && totalCartItemPrice < FREE_SHIPPING_AMOUNT && (
                                <div className={styles['headerInfo']}>
                                    <FormattedMessage
                                        id="components.shoppingCart.shoppingCart.amountUntilFreeShipping"
                                        defaultMessage="Iki nemokamo siuntimo trūksta {amountUntilFreeShipping} €"
                                        values={{
                                            amountUntilFreeShipping:
                                                amountUntilFreeShipping.toFixed(2),
                                        }}
                                    />
                                </div>
                            )}
                            <div className={styles['shoppingCartIcon']}>
                                <ShoppingCartIcon />
                            </div>
                        </div>
                        {cartItems.length !== 0 && (
                            <div className={styles['labelsWrapper']}>
                                <ShoppingCartLabels />
                            </div>
                        )}
                    </div>
                    {cartItems.length === 0 && (
                        <div className={styles['emptyCart']}>
                            <FormattedMessage
                                id="componnts.shoppingCart.shoppingCartItems.emptyCart"
                                defaultMessage="Jūsų krepšelis tuščias"
                            />
                        </div>
                    )}
                    {cartItems.length !== 0 && (
                        <>
                            <div className={styles['itemsWrapper']}>
                                <ShoppingCartItemsWrapper
                                    cartItems={cartItems}
                                    setTotalCartItemPrice={setTotalCartItemPrice}
                                    setAmountUntilFreeShipping={setAmountUntilFreeShipping}
                                />
                            </div>
                            <div className={styles['total']}>
                                <div className={styles['totalTitle']}>
                                    <span>
                                        <FormattedMessage
                                            id="components.shoppingCart.shoppingCart.totalSum"
                                            defaultMessage="Bendra suma:"
                                        />
                                    </span>
                                    <span>{totalCartItemPrice.toFixed(2)} €</span>
                                </div>
                                <div className={styles['checkoutButtonWrapper']}>
                                    <Button
                                        type="CTA"
                                        onClick={proceedTocheckout}
                                        loading={isLoading}
                                        disabled={isLoading}
                                        focusable={showCart}
                                    >
                                        <FormattedMessage
                                            id="components.shoppingCart.shoppingCart.proceedToCheckout"
                                            defaultMessage="Pirkti"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShoppingCart;
