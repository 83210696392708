import { FC, useEffect, KeyboardEvent } from 'react';

import { showNavVar } from '@/apollo/reactiveVars';
import { useWindowDimensions } from '@/hooks';

import { navItems } from '../navItems';
import NavItem from './NavItem';

import styles from './MobileNav.module.scss';

const MobileNavigation: FC = () => {
    const { width } = useWindowDimensions();

    useEffect(() => {
        // 768px used for @tablet breakpoint
        if (width && width >= 768) {
            showNavVar(false);
        }
    }, [width]);

    const handleKeyDown = (event: KeyboardEvent<HTMLAnchorElement>): void => {
        if (event.key === 'Enter' || event.key === ' ') {
            showNavVar(false);
        }
    };

    return (
        <div className={styles['mobileNav']}>
            {navItems.map(item => (
                <NavItem key={item.title.id} handleKeyDown={handleKeyDown} {...item} />
            ))}
        </div>
    );
};

export default MobileNavigation;
