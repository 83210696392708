import { FC } from 'react';
import classnames from 'classnames';

import styles from './ExpandIcon.module.scss';

type Props = {
    expanded: boolean;
};

const ExpandIcon: FC<Props> = ({ expanded }) => (
    <span
        className={classnames(styles['ExpandIcon'], {
            [styles['expanded']]: expanded,
        })}
    ></span>
);

export default ExpandIcon;
