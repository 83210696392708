import { FC, MouseEventHandler, MutableRefObject, ReactNode } from 'react';
import classnames from 'classnames';

import { Spinner } from '@/ui';

import styles from './Button.module.scss';

type Props = {
    children?: ReactNode;
    buttonRef?: MutableRefObject<HTMLButtonElement>;
    onClick?: MouseEventHandler;
    type?: 'CTA' | 'primary' | 'secondary';
    variant?: 'fullWidth' | 'fullHeight' | 'fill' | 'strikethrough';
    className?: string;
    underline?: boolean;
    disabled?: boolean;
    loading?: boolean;
    focusable?: boolean;
    hasPadding?: boolean;
    ariaLabel?: string;
};

const Button: FC<Props> = ({
    children,
    buttonRef,
    onClick,
    type,
    variant,
    className = '',
    ariaLabel,
    underline = false,
    disabled = false,
    loading = false,
    focusable = true,
    hasPadding = true,
}) => {
    if (type === 'CTA') {
        type = 'primary';
        variant = 'fill';
    }

    return (
        <button
            className={classnames(styles['button'], className, {
                [styles[`${type}`]]: type,
                [styles[`${variant}`]]: variant,
                [styles['loading']]: loading,
                [styles['disabled']]: disabled,
                [styles['underline']]: underline,
                [styles['hasPadding']]: hasPadding,
            })}
            ref={buttonRef}
            tabIndex={focusable ? 0 : -1}
            onClick={onClick}
            disabled={disabled}
            aria-label={ariaLabel}
        >
            {children}
            {loading && (
                <span className={styles['loadingWrapper']}>
                    <Spinner size="tiny" white={type === 'primary'} />
                </span>
            )}
        </button>
    );
};

export default Button;
