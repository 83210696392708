import getShoppingCartProductVariantData from './getShoppingCartProductVariantData';
import { getDiscountPrice } from '@/crystallize/helpers';

import { CartItem } from '@/apollo/reactiveVars';
import { ProductFragment } from '@/catalogueFragments';

const getTotalPrice = (cartItems: CartItem[], shoppingCartItems: ProductFragment[]): number => {
    return cartItems.reduce((sum, item) => {
        const { priceVariants, price } =
            getShoppingCartProductVariantData(item, shoppingCartItems) || {};
        const discountPrice = getDiscountPrice(priceVariants);
        const itemPrice = discountPrice || price;

        if (itemPrice) {
            return sum + itemPrice * item.quantity;
        }

        return sum;
    }, 0);
};

export default getTotalPrice;
