import * as Types from '../../schema';

import { gql } from '@apollo/client';
export type ProductFragment = {
    __typename: 'Product';
    id: string;
    name?: string | null;
    path?: string | null;
    title?: {
        __typename?: 'Component';
        content?:
            | { __typename?: 'BooleanContent' }
            | { __typename?: 'ComponentChoiceContent' }
            | { __typename?: 'ContentChunkContent' }
            | { __typename?: 'DatetimeContent' }
            | { __typename?: 'FileContent' }
            | { __typename?: 'GridRelationsContent' }
            | { __typename?: 'ImageContent' }
            | { __typename?: 'ItemRelationsContent' }
            | { __typename?: 'LocationContent' }
            | { __typename?: 'NumericContent' }
            | { __typename?: 'ParagraphCollectionContent' }
            | { __typename?: 'PropertiesTableContent' }
            | { __typename?: 'RichTextContent' }
            | { __typename?: 'SelectionContent' }
            | { __typename: 'SingleLineContent'; text?: string | null }
            | { __typename?: 'VideoContent' }
            | null;
    } | null;
    topics?: Array<{
        __typename?: 'Topic';
        id: string;
        parentId?: string | null;
        name: string;
    }> | null;
    variants?: Array<{
        __typename?: 'ProductVariant';
        id: string;
        name?: string | null;
        sku: string;
        stock?: number | null;
        price?: number | null;
        priceVariants?: Array<{
            __typename?: 'ProductPriceVariant';
            identifier: string;
            price?: number | null;
        }> | null;
        images?: Array<{
            __typename?: 'Image';
            url: string;
            altText?: string | null;
            variants?: Array<{
                __typename?: 'ImageVariant';
                url: string;
                width: number;
                height?: number | null;
            }> | null;
        }> | null;
        attributes?: Array<{
            __typename?: 'ProductVariantAttribute';
            attribute: string;
            value?: string | null;
        } | null> | null;
    }> | null;
};

export type TopicFragment = {
    __typename?: 'Topic';
    id: string;
    parentId?: string | null;
    name: string;
};

export type ProductVariantFragment = {
    __typename?: 'ProductVariant';
    id: string;
    name?: string | null;
    sku: string;
    stock?: number | null;
    price?: number | null;
    priceVariants?: Array<{
        __typename?: 'ProductPriceVariant';
        identifier: string;
        price?: number | null;
    }> | null;
    images?: Array<{
        __typename?: 'Image';
        url: string;
        altText?: string | null;
        variants?: Array<{
            __typename?: 'ImageVariant';
            url: string;
            width: number;
            height?: number | null;
        }> | null;
    }> | null;
    attributes?: Array<{
        __typename?: 'ProductVariantAttribute';
        attribute: string;
        value?: string | null;
    } | null> | null;
};

export type ImageFragment = {
    __typename?: 'Image';
    url: string;
    altText?: string | null;
    variants?: Array<{
        __typename?: 'ImageVariant';
        url: string;
        width: number;
        height?: number | null;
    }> | null;
};

export type RichTextContentFragment = {
    __typename: 'RichTextContent';
    plainText?: Array<string> | null;
};

export type SingleLineContentFragment = { __typename: 'SingleLineContent'; text?: string | null };

export const SingleLineContentFragmentDoc = gql`
    fragment singleLineContent on SingleLineContent {
        __typename
        text
    }
`;
export const TopicFragmentDoc = gql`
    fragment topic on Topic {
        id
        parentId
        name
    }
`;
export const ImageFragmentDoc = gql`
    fragment image on Image {
        url
        altText
        variants {
            url
            width
            height
        }
    }
`;
export const ProductVariantFragmentDoc = gql`
    fragment productVariant on ProductVariant {
        id
        name
        sku
        stock
        price
        priceVariants {
            identifier
            price
        }
        stock
        images {
            ...image
        }
        attributes {
            attribute
            value
        }
    }
    ${ImageFragmentDoc}
`;
export const ProductFragmentDoc = gql`
    fragment product on Product {
        __typename
        id
        name
        path
        title: component(id: "title") {
            content {
                ...singleLineContent
            }
        }
        topics {
            ...topic
        }
        variants {
            ...productVariant
        }
    }
    ${SingleLineContentFragmentDoc}
    ${TopicFragmentDoc}
    ${ProductVariantFragmentDoc}
`;
export const RichTextContentFragmentDoc = gql`
    fragment richTextContent on RichTextContent {
        __typename
        plainText
    }
`;
