export const SUPPORT_PHONE = '+37065628600';
export const SUPPORT_PHONE_PRESENTATIONAL = '+ 370 6 562 86 00';
export const SUPPORT_EMAIL = 'info@balticchinchillas.com';

export const DOMAIN = 'balticchinchillas.com';
export const DOMAIN_URL = 'https://balticchinchillas.com';
export const QA_DOMAIN_URL = 'https://bc-shop-simasq.vercel.app';

export const COMPANY_NAME = 'MB Baltic Chinchillas';
export const COMPANY_CODE = '306093594';
export const COMPANY_ADDRESS = 'Laisvės pr. 88, LT-06125, Vilnius';
export const COMPANY_BANK_ACCOUNT = 'LT487300010172530764';
export const COMPANY_BANK = 'Swedbank';
export const COMPANY_BANK_CODE = '73000';

export const SOCIAL_FACEBOOK_URL = 'https://www.facebook.com/balticchinchillas/';
export const SOCIAL_INSTAGRAM_URL = 'https://www.instagram.com/balticchinchillas/';

export const FREE_SHIPPING_AMOUNT = 40;
export const SHIPPING_PRICE = 3;

export const OXBOW_LINK = 'https://www.oxbowanimalhealth.com/';
export const HAPPY_ONE_LINK = 'https://mediterraneum.pt/en';
export const FRESH_NEWS_LINK = 'https://www.freshnewslitter.com/';

export const IS_DEVELOPMENT = process.env.NEXT_PUBLIC_NODE_SDLC === 'development';
export const IS_PREVIEW = process.env.NEXT_PUBLIC_NODE_SDLC === 'preview';

export const REFERRAL_QUERY_PARAM = 'bc-referral';

// referal codes
export const REFERAL_DARIUS_MILBUTAS = '34439';
