import { FC, useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import classnames from 'classnames';

import {
    cartItemsVar,
    showCartIconAnimationVar,
    showCartVar,
    showNavVar,
} from '@/apollo/reactiveVars';

import { Button } from '@/ui';
import { ShoppingCart } from '@/components/Icons';

import styles from './ShoppingCartIcon.module.scss';

type Props = {
    interactive?: boolean;
};

const ShoppingCartIcon: FC<Props> = ({ interactive = false }) => {
    const [count, setCount] = useState(0);
    const cartItems = useReactiveVar(cartItemsVar);
    const showCart = useReactiveVar(showCartVar);
    const showCartIcon = useReactiveVar(showCartIconAnimationVar);
    const showNav = useReactiveVar(showNavVar);
    const focusable = !showNav && !showCart && interactive;

    useEffect(() => {
        const getCount = (): number => {
            let total = 0;
            cartItems.forEach(item => {
                total += item.quantity;
            });
            return total;
        };
        const itemNumberInCart = getCount();
        setCount(itemNumberInCart);
    }, [cartItems]);

    return interactive ? (
        <Button
            focusable={focusable}
            onClick={(): boolean => showCartVar(true)}
            className={classnames(styles['shoppingCart'], styles['button'], {
                [styles['hidden']]: showNav,
                [styles['animating']]: showCartIcon,
            })}
        >
            <ShoppingCart width={32} height={32} />
            <span className={styles['count']}>{count}</span>
        </Button>
    ) : (
        <div
            className={classnames(styles['shoppingCart'], {
                [styles['hidden']]: showNav,
                [styles['animating']]: showCartIcon,
            })}
        >
            <ShoppingCart width={32} height={32} />
            <span className={styles['count']}>{count}</span>
        </div>
    );
};

export default ShoppingCartIcon;
