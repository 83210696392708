import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const ShoppingCart: FC<Props> = ({ className, width = 32, height = 32 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        style={{ verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }}
        viewBox="0 0 1024 1024"
    >
        <path d="M511.998465 63.83998c-83.301218 0-151.071717 67.771523-151.071717 151.073764l0 99.745748-93.924159 0c-39.083134 0-70.883397 28.199249-70.883397 67.274196l0 503.735779c0 39.083134 31.800263 74.490551 70.883397 74.490551l489.9938 0c39.083134 0 70.88442-35.407418 70.88442-74.490551L827.880809 381.933689c0-39.07597-31.800263-67.274196-70.88442-67.274196l-93.924159 0 0-99.745748C663.072229 131.611503 595.301729 63.83998 511.998465 63.83998zM388.393309 214.913744c0-68.159356 55.4458-123.605156 123.605156-123.605156 68.161403 0 123.606179 55.4458 123.606179 123.605156l0 99.745748L388.393309 314.659493 388.393309 214.913744zM756.996388 342.126055c23.941272 0 43.414789 15.873526 43.414789 39.806611l0 503.735779c0 23.941272-19.472494 47.022966-43.414789 47.022966L267.001565 932.691411c-23.941272 0-43.414789-23.082718-43.414789-47.022966L223.586776 381.933689c0-23.934109 19.47454-39.806611 43.414789-39.806611l93.924159 0 0 51.335179c0 7.583722 6.142907 13.733792 13.733792 13.733792 7.591909 0 13.733792-6.15007 13.733792-13.733792l0-51.335179 247.211335 0 0 51.335179c0 7.583722 6.141884 13.733792 13.734816 13.733792 7.590885 0 13.732769-6.15007 13.732769-13.733792l0-51.335179L756.996388 342.127078z" />
    </svg>
);

export default ShoppingCart;
