import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import { cartItemsVar } from '@/apollo/reactiveVars';

import { ShoppingCartItem } from '../ShoppingCartItem';

import { getShoppingCartProductData } from '../helpers';
import {
    getDiscountPrice,
    getProductTypeTopic,
    getSizeAttribute,
    getThumbImgSrc,
    getProductName,
} from '@/crystallize/helpers';

import { ProductFragment } from '@/catalogueFragments';

type Props = {
    shoppingCartItems: ProductFragment[];
};

const ShoppingCartItems: FC<Props> = ({ shoppingCartItems }) => {
    const cartItems = useReactiveVar(cartItemsVar);

    return (
        <div>
            {cartItems
                .map(item => {
                    const { id, title, variants, topics, path } =
                        getShoppingCartProductData(item, shoppingCartItems) || {};
                    const variant = (variants || []).find(v => v?.sku === item.sku);
                    const { sku, price, stock, priceVariants, images, attributes } = variant || {};
                    const name = getProductName(title);
                    const discountPrice = getDiscountPrice(priceVariants);
                    const size = getSizeAttribute(attributes);
                    const productType = getProductTypeTopic(topics);
                    const thumbImageSrc = getThumbImgSrc(images);
                    const altText = (images || [])[0]?.altText;
                    const productStock = stock || 0;

                    const hasProps = !!id && !!name && !!sku && !!price && !!path && !!size;

                    return hasProps ? (
                        <ShoppingCartItem
                            key={sku}
                            productId={id}
                            sku={sku}
                            path={path}
                            name={name}
                            src={thumbImageSrc}
                            alt={altText || ''}
                            price={price}
                            discountPrice={discountPrice}
                            quantity={item.quantity}
                            size={size}
                            productType={productType || ''}
                            invalidStock={productStock < item.quantity}
                            productStock={productStock}
                            cartItems={cartItems}
                            cartItemsVar={cartItemsVar}
                        />
                    ) : null;
                })
                .filter(Boolean)}
        </div>
    );
};

export default ShoppingCartItems;
