import { defineMessages, MessageDescriptor } from 'react-intl';

const messages = defineMessages({
    allProducts: {
        id: 'components.nav.navItems.allProducts',
        defaultMessage: 'visa produkcija',
    },
    smallPets: {
        id: 'components.nav.navItems.smallPets',
        defaultMessage: 'graužikams',
    },
    allSmallPets: {
        id: 'components.nav.navItems.allSmallPets',
        defaultMessage: 'viskas graužikams',
    },
    dogs: {
        id: 'components.nav.navItems.dogs',
        defaultMessage: 'šunims',
    },
    food: {
        id: 'components.nav.navItems.food',
        defaultMessage: 'maistas',
    },
    hay: {
        id: 'components.nav.navItems.hay',
        defaultMessage: 'šienas',
    },
    treats: {
        id: 'components.nav.navItems.treats',
        defaultMessage: 'skanėstai',
    },
    supplements: {
        id: 'components.nav.navItems.supplements',
        defaultMessage: 'papildai',
    },
    accessories: {
        id: 'components.nav.navItems.accessories',
        defaultMessage: 'priedai',
    },
    litter: {
        id: 'components.nav.navItems.litter',
        defaultMessage: 'kraikas',
    },
    about: {
        id: 'components.nav.navItems.about',
        defaultMessage: 'apie mus',
    },
    contacts: {
        id: 'components.nav.navItems.contacts',
        defaultMessage: 'kontaktai',
    },
});

export type NavItemType = {
    title: MessageDescriptor;
    path: string | null;
    mainRoot?: boolean;
    secondaryNav:
        | {
              path: string;
              title: MessageDescriptor;
          }[]
        | null;
};

export const navItems: NavItemType[] = [
    {
        title: messages.allProducts,
        path: null,
        mainRoot: true,
        secondaryNav: [
            {
                title: messages.allProducts,
                path: '/',
            },
            {
                title: messages.food,
                path: '/shop/product-types/food/',
            },
            {
                title: messages.hay,
                path: '/shop/product-types/hay/',
            },
            {
                title: messages.treats,
                path: '/shop/product-types/treats/',
            },
            {
                title: messages.supplements,
                path: '/shop/product-types/supplements/',
            },
            {
                title: messages.accessories,
                path: '/shop/product-types/accessories/',
            },
            {
                title: messages.litter,
                path: '/shop/product-types/litter/',
            },
        ],
    },
    {
        title: messages.smallPets,
        path: null,
        secondaryNav: [
            {
                title: messages.allSmallPets,
                path: '/small-pets/',
            },
            {
                title: messages.food,
                path: '/small-pets/product-types/food/',
            },
            {
                title: messages.hay,
                path: '/small-pets/product-types/hay/',
            },
            {
                title: messages.treats,
                path: '/small-pets/product-types/treats/',
            },
            {
                title: messages.supplements,
                path: '/small-pets/product-types/supplements/',
            },
            {
                title: messages.accessories,
                path: '/small-pets/product-types/accessories/',
            },
            {
                title: messages.litter,
                path: '/small-pets/product-types/litter/',
            },
        ],
    },
    {
        title: messages.dogs,
        path: null,
        secondaryNav: [
            {
                title: messages.food,
                path: '/dogs/product-types/food/',
            },
        ],
    },
    {
        title: messages.about,
        path: '/about/',
        secondaryNav: null,
    },
    {
        title: messages.contacts,
        path: '/contact/',
        secondaryNav: null,
    },
];
