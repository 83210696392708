import { FC, KeyboardEventHandler, MouseEventHandler, MutableRefObject, ReactNode } from 'react';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import Button from '../Button';

import { getPathFromUrl } from '@/helpers';

import styles from './Link.module.scss';

type Props = {
    children?: ReactNode;
    linkRef?: MutableRefObject<HTMLAnchorElement | HTMLButtonElement>;
    focusable?: boolean;
    newTab?: boolean;
    className?: string;
    activeClassName?: string;
    href?: string;
    black?: boolean;
    underline?: boolean;
    onClick?: MouseEventHandler;
    onKeyDown?: KeyboardEventHandler;
} & Omit<LinkProps, 'href'>;

const Link: FC<Props> = ({
    children,
    linkRef,
    href,
    focusable = true,
    newTab = false,
    prefetch,
    as,
    scroll,
    className,
    activeClassName,
    black = false,
    underline = false,
    onKeyDown,
    ...props
}) => {
    const router = useRouter();

    if (href) {
        const isExternal =
            (typeof href === 'string' &&
                (href.indexOf('http') === 0 || href.indexOf('tel:') === 0)) ||
            href.indexOf('mailto:') === 0;

        const newTabProps = newTab
            ? { target: '_blank', rel: 'noopener noreferrer' }
            : {
                  target: undefined,
                  rel: undefined,
              };

        const showActiveClass = href === router.asPath;

        const Anchor = (
            <a
                ref={linkRef as MutableRefObject<HTMLAnchorElement>}
                className={classnames(
                    styles['link'],
                    className,
                    showActiveClass && activeClassName,
                    {
                        [styles['black']]: black,
                        [styles['underline']]: underline,
                    }
                )}
                href={getPathFromUrl(href)}
                tabIndex={focusable ? 0 : -1}
                onKeyDown={onKeyDown}
                {...newTabProps}
                {...props}
            >
                {children}
            </a>
        );

        return isExternal ? (
            Anchor
        ) : (
            <NextLink href={href} prefetch={prefetch && undefined} as={as} scroll={scroll} passHref>
                {Anchor}
            </NextLink>
        );
    }

    return (
        <Button
            buttonRef={linkRef as MutableRefObject<HTMLButtonElement>}
            className={className}
            focusable={focusable}
            {...props}
        >
            {children}
        </Button>
    );
};

export default Link;
