import { CartItem } from '@/apollo/reactiveVars';
import { ProductFragment } from '@/catalogueFragments';

export type ShoppingCartItemQuery = {
    __typename?: 'Query';
    [key: `product${number}`]: ProductFragment;
};

const generateCartItemsQuery = (cartItems: CartItem[], locale?: string): string => `
    query GetCartItems {
        ${cartItems.map((item, idx) => {
            const { path } = item || {};
            return `
            product${idx}: catalogue(path: "${path}", language: "${
                locale || ''
            }") @include(if: ${!!path}) {
                ...product
            }
        `;
        })}
    }
    `;

export default generateCartItemsQuery;
