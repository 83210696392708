import { useState, useEffect } from 'react';

/**
  Returns `true` when component mounts.
  Doesn't track unmounts.
*/
export default function useHasMounted(): boolean {
    const [hasMounted, setHasMounted] = useState(false);
    useEffect(() => {
        setHasMounted(true);
    }, []);
    return hasMounted;
}
