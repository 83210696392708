import { FC, Dispatch, SetStateAction, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery, gql } from '@apollo/client';

import { ShoppingCartItems } from '../ShoppingCartItems';

import { generateCartItemsQuery, getTotalPrice, ShoppingCartItemQuery } from '../helpers';

import { FREE_SHIPPING_AMOUNT } from '@/data/constants';

import { ProductFragment, ProductFragmentDoc } from '@/catalogueFragments';
import { CartItem } from '@/apollo/reactiveVars';

type Props = {
    cartItems: CartItem[];
    setTotalCartItemPrice: Dispatch<SetStateAction<number>>;
    setAmountUntilFreeShipping: Dispatch<SetStateAction<number>>;
};

const ShoppingCartItemsWrapper: FC<Props> = ({
    cartItems,
    setTotalCartItemPrice,
    setAmountUntilFreeShipping,
}) => {
    const { locale } = useRouter();
    const { data } = useQuery<ShoppingCartItemQuery>(
        gql`${generateCartItemsQuery(cartItems, locale)},${ProductFragmentDoc}`,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    const shoppingCartItems = cartItems
        .map((_, idx) => data?.[`product${idx}`])
        .filter(p => !!p) as ProductFragment[];

    useEffect(() => {
        if (cartItems.length > 0) {
            const totalPrice = getTotalPrice(cartItems, shoppingCartItems);
            const newAmountUntilFreeShipping = FREE_SHIPPING_AMOUNT - totalPrice;

            if (setTotalCartItemPrice) {
                setTotalCartItemPrice(totalPrice);
            }

            if (setAmountUntilFreeShipping) {
                setAmountUntilFreeShipping(newAmountUntilFreeShipping);
            }
        }
    }, [cartItems, shoppingCartItems, setTotalCartItemPrice, setAmountUntilFreeShipping]);

    return <ShoppingCartItems shoppingCartItems={shoppingCartItems} />;
};

export default ShoppingCartItemsWrapper;
