import { useEffect } from 'react';

/**
 * Calls callback for given `key | key[]`.
 */
export default function useKeyDown(key: string | string[], callback: () => void): void {
    useEffect(() => {
        const keyVal = Array.isArray(key) ? key : [key];

        const handleKeyDown = (event: KeyboardEvent): void => {
            if (keyVal.includes(event.key)) {
                callback();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [callback, key]);
}
