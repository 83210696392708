import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

export const cache: InMemoryCache = new InMemoryCache({
    addTypename: true,
    typePolicies: {
        Catalogue: {
            keyFields: [],
        },
        Folder: {
            keyFields: [],
            fields: {
                subtree: relayStylePagination(['path', 'language', '@connection', ['key']]),
            },
        },
        Topic: {
            fields: {
                items: relayStylePagination(['path', 'language', '@connection', ['key']]),
            },
        },
        Image: {
            keyFields: ['url'],
        },
        ImageVariant: {
            keyFields: ['url'],
        },
    },
});
