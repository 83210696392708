import { FC, useEffect, useLayoutEffect, useRef, useState, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import { useKeyDown } from '@/hooks';

import { Close } from '@/components/Icons';
import { Button } from '@/ui';

import { toggleBodyFixedClass } from '@/helpers';

import styles from './Modal.module.scss';

type Props = {
    children?: ReactNode;
    isOpen: boolean;
    transparent?: boolean;
    closeButton?: boolean;
    closeOnClick?: boolean;
    size?: 'small' | 'medium' | 'large';
    onClose?: () => void;
};

const Modal: FC<Props> = ({
    children,
    isOpen = false,
    transparent = false,
    size = 'large',
    onClose,
    closeOnClick = false,
    closeButton = true,
}) => {
    const [showModal, setShowModal] = useState(isOpen);
    const elRef = useRef(document.createElement('div'));
    const modalRoot = document.getElementById('modal');

    const handleClick = (): void => {
        setShowModal(false);
        if (onClose) {
            onClose();
        }
    };

    useKeyDown('Escape', () => {
        handleClick();
    });

    useLayoutEffect(() => {
        if (!modalRoot) {
            return;
        }

        const modal = elRef.current;
        modalRoot.appendChild(modal);

        return () => {
            modalRoot.removeChild(modal);
        };
    }, [modalRoot]);

    useEffect(() => {
        if (showModal) {
            toggleBodyFixedClass(true);
        }

        return () => {
            toggleBodyFixedClass(false);
        };
    }, [showModal]);

    if (!showModal) {
        return null;
    }

    return createPortal(
        <>
            <div className={styles['overlay']} role="presentation" onClick={handleClick} />
            {/* @ts-ignore Type '{}' is not assignable to type 'ReactNode' */}
            <CSSTransition
                timeout={0}
                appear={true}
                in={true}
                classNames={{
                    enterDone: styles['fadeIn'],
                }}
            >
                <div
                    onClick={closeOnClick ? handleClick : undefined}
                    className={classnames(styles['modal'], {
                        [styles['transparent']]: transparent,
                        [styles['large']]: size === 'large',
                        [styles['medium']]: size === 'medium',
                        [styles['small']]: size === 'small',
                    })}
                >
                    <div className={styles['body']}>
                        {closeButton && (
                            <div className={styles['buttonWrapper']}>
                                <Button className={styles['close']} onClick={handleClick}>
                                    <Close />
                                </Button>
                            </div>
                        )}
                        <div className={styles['content']}>{children}</div>
                    </div>
                </div>
            </CSSTransition>
        </>,
        elRef.current
    );
};

export default Modal;
