import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const NumberOne: FC<Props> = ({ className, width = 640, height = 640 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640"
    >
        <defs>
            <path
                d="M0 320C0 496 144 640 320 640C496 640 640 496 640 320C640 144 496 0 320 0C144 0 0 144 0 320ZM312 467.2L312 256L235.2 256C235.2 238.72 235.2 229.12 235.2 227.2C280 225.6 312 216 320 171.2C321.81 171.2 330.88 171.2 347.2 171.2L347.2 467.2L312 467.2Z"
                id="bbg15wcuN"
            ></path>
        </defs>
        <g>
            <g>
                <use xlinkHref="#bbg15wcuN" opacity="1" fill="currentColor" fillOpacity="1"></use>
                <g>
                    <use
                        xlinkHref="#bbg15wcuN"
                        opacity="1"
                        fillOpacity="0"
                        stroke="currentColor"
                        strokeWidth="1"
                        strokeOpacity="0"
                    ></use>
                </g>
            </g>
        </g>
    </svg>
);

export default NumberOne;
