import { FC, useState, useRef, KeyboardEvent } from 'react';
import { useRouter } from 'next/router';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';
import classnames from 'classnames';

import { showNavVar } from '@/apollo/reactiveVars';

import { ExpandIcon, Link } from '@/ui';

import styles from './MobileNav.module.scss';

type Props = {
    handleKeyDown: (event: KeyboardEvent<HTMLAnchorElement>) => void;
    title: MessageDescriptor;
    secondaryNav: {
        title: MessageDescriptor;
        path: string;
    }[];
    mainRoot?: boolean;
};

const NavItemWithChildren: FC<Props> = props => {
    const { formatMessage } = useIntl();
    const router = useRouter();
    const elRef = useRef<HTMLDivElement>(null);
    const showNav = useReactiveVar(showNavVar);
    const [showSecondaryNavigation, setShowSecondaryNavigation] = useState(false);
    const [secondaryNavHeight, setSecondaryNavHeight] = useState(0);
    const hasPath = props.secondaryNav.map(item => item.path).includes(router.asPath.split('?')[0]);
    const isMainRoot = router.asPath === '/';
    const showActiveLink = isMainRoot && props.mainRoot ? hasPath : hasPath && !isMainRoot;
    const currentHeight = showSecondaryNavigation ? secondaryNavHeight + 1 : 0;

    const handleClick = (): void => {
        if (elRef.current) {
            setSecondaryNavHeight(elRef.current.clientHeight);
            setShowSecondaryNavigation(!showSecondaryNavigation);
        }
    };

    return (
        <>
            <Link
                onClick={handleClick}
                focusable={showNav}
                className={classnames(styles['link'], { [styles['activeLink']]: showActiveLink })}
            >
                <span className={styles['linkInnerWrapper']}>
                    {formatMessage(props.title)}
                    <span className={styles['icon']}>
                        <ExpandIcon expanded={showSecondaryNavigation} />
                    </span>
                </span>
            </Link>
            <div className={styles['secondaryNavWrapper']} style={{ height: currentHeight + 'px' }}>
                <div
                    className={classnames(styles['secondaryNav'], {
                        [styles['expanded']]: showSecondaryNavigation,
                    })}
                    ref={elRef}
                >
                    {props.secondaryNav.map(({ title, path }, idx) => (
                        <Link
                            key={`${path}-${idx}`}
                            href={path}
                            onClick={(): boolean => showNavVar(false)}
                            onKeyDown={props.handleKeyDown}
                            focusable={showNav && showSecondaryNavigation}
                            className={classnames(styles['link'], styles['secondary'])}
                            activeClassName={styles['activeLink']}
                        >
                            <span>{formatMessage(title)}</span>
                        </Link>
                    ))}
                </div>
            </div>
        </>
    );
};

export default NavItemWithChildren;
