import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './ShoppingCartLabels.module.scss';

const ShoppingCartLabels: FC = () => (
    <div className={styles['wrapper']}>
        <div className={styles['imageSpacer']} />
        <ul className={styles['labels']}>
            <li className={styles['descriptionSpacer']} />
            <li className={styles['price']}>
                <FormattedMessage
                    id="components.shoppingCart.shoppingCartLabels.price"
                    defaultMessage="Kaina"
                />
            </li>
            <li className={styles['quantity']}>
                <FormattedMessage
                    id="components.shoppingCart.shoppingCartLabels.quantity"
                    defaultMessage="Kiekis"
                />
            </li>
            <li className={styles['totalPrice']}>
                <FormattedMessage
                    id="components.shoppingCart.shoppingCartLabels.sum"
                    defaultMessage="Suma"
                />
            </li>
            <li className={styles['removeButtonSpacer']} />
        </ul>
    </div>
);

export default ShoppingCartLabels;
