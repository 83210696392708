import { FC, KeyboardEvent, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { defineMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

import { showCartVar, showNavVar } from '@/apollo/reactiveVars';

import { toggleBodyFixedClass } from '@/helpers';

import styles from './NavIcon.module.scss';

const message = defineMessage({
    id: 'components.nav.navIcon.menu',
    defaultMessage: 'meniu',
});

const NavigationIcon: FC = () => {
    const { formatMessage } = useIntl();
    const showCart = useReactiveVar(showCartVar);
    const showNav = useReactiveVar(showNavVar);

    const toggleShowNavigation = (): void => {
        showNavVar(!showNav);
    };

    useEffect(() => {
        toggleBodyFixedClass(showNav);
    }, [showNav]);

    return (
        <div
            className={classnames(styles['navIcon'], {
                [styles['expanded']]: showNav,
            })}
            onClick={toggleShowNavigation}
            role="button"
            aria-label={formatMessage(message)}
            tabIndex={showCart ? -1 : 0}
            onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
                if (event.key === ' ' || event.key === 'Enter') {
                    event.preventDefault();
                    toggleShowNavigation();
                }
            }}
        >
            <span className={styles['content']}></span>
            <span className={styles['content']}></span>
            <span className={styles['content']}></span>
        </div>
    );
};

export default NavigationIcon;
