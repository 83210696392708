import { makeVar } from '@apollo/client';

export type CartItem = {
    productId: string;
    sku: string;
    path: string;
    quantity: number;
};

const cartItemsVar = makeVar<CartItem[]>([]);

export default cartItemsVar;
