import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import styles from './ErrorText.module.scss';

type Props = {
    children?: ReactNode;
    className?: string;
};

const ErrorText: FC<Props> = ({ children, className }) => (
    <div className={classnames(styles['error'], className)}>{children}</div>
);

export default ErrorText;
