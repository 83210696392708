import { ElementType, HTMLAttributes, ReactElement, RefObject } from 'react';
import classnames from 'classnames';

import styles from './Container.module.scss';

interface Props<T> extends HTMLAttributes<HTMLOrSVGElement> {
    as?: ElementType;
    elRef?: RefObject<T>;
    className?: string;
    noGutter?: boolean;
    fullWidth?: boolean;
    contentCenter?: boolean;
}

const Container = <T,>({
    children,
    as: Tag = 'div',
    elRef,
    className,
    noGutter = false,
    fullWidth = false,
    contentCenter = false,
}: Props<T>): ReactElement => {
    return (
        <Tag
            ref={elRef}
            className={classnames(styles['container'], className, {
                [styles['noGutter']]: noGutter,
                [styles['fullWidth']]: fullWidth,
                [styles['contentCenter']]: contentCenter,
            })}
        >
            {children}
        </Tag>
    );
};

export default Container;
