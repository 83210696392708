import { FC } from 'react';

import { navItems } from '../navItems';
import NavItem from './NavItem';

import styles from './DesktopNav.module.scss';

const DesktopNavigation: FC = () => (
    <div className={styles['desktopNav']}>
        {navItems.map(item => (
            <NavItem key={item.title.id} {...item} />
        ))}
    </div>
);

export default DesktopNavigation;
