import { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useIntl } from 'react-intl';

import { showCartVar } from '@/apollo/reactiveVars';

import { Link } from '@/ui';

import NavItemWithChildren from './NavItemWithChildren';

import { NavItemType } from '../navItems';

import styles from './DesktopNav.module.scss';

type Props = NavItemType;

const NavItem: FC<Props> = ({ title, path, secondaryNav, mainRoot }) => {
    const { formatMessage } = useIntl();
    const showCart = useReactiveVar(showCartVar);

    if (secondaryNav) {
        return (
            <NavItemWithChildren
                title={title}
                secondaryNav={secondaryNav}
                showCart={showCart}
                mainRoot={mainRoot}
            />
        );
    }

    return (
        <Link
            href={path || ''}
            className={styles['link']}
            focusable={!showCart}
            activeClassName={styles['activeLink']}
        >
            {formatMessage(title)}
        </Link>
    );
};

export default NavItem;
