import { FC, HTMLAttributes, ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import classnames from 'classnames';

import { ErrorText } from '@/ui';

import styles from './Input.module.scss';

type Props = {
    autoCapitalize?: boolean;
    autoComplete?: boolean;
    autoCorrect?: boolean;
    className?: string;
    defaultValue?: string | number;
    disabled?: boolean;
    errorClass?: string;
    errorMessage?: ReactNode;
    hasError?: boolean;
    inputClass?: string;
    inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
    label?: ReactNode;
    labelClass?: string;
    name?: string;
    placeholder?: string;
    register?: UseFormRegisterReturn;
    type?: string;
    wrapperClass?: string;
};

const Input: FC<Props> = ({
    autoCapitalize,
    autoComplete,
    autoCorrect,
    defaultValue,
    disabled,
    errorClass,
    errorMessage,
    hasError,
    inputClass,
    inputMode = 'text',
    label,
    labelClass,
    name,
    placeholder,
    register,
    type,
    wrapperClass,
}) => {
    const autoCapitalizeProp = autoCapitalize ? 'on' : 'off';
    const autoCompleteProp = autoComplete ? 'on' : 'off';
    const autoCorrectProp = autoCorrect ? 'on' : 'off';

    const wrapperClasses = classnames(styles['wrapper'], wrapperClass);
    const labelClasses = classnames(styles['label'], labelClass);
    const inputClasses = classnames(styles['input'], inputClass, {
        [styles['error']]: hasError,
    });

    return (
        <div className={wrapperClasses}>
            {label && <div className={labelClasses}>{label}</div>}
            <input
                autoCapitalize={autoCapitalizeProp}
                autoComplete={autoCompleteProp}
                autoCorrect={autoCorrectProp}
                className={inputClasses}
                defaultValue={defaultValue}
                disabled={disabled}
                type={type}
                inputMode={inputMode}
                placeholder={placeholder}
                name={name}
                {...register}
            />
            {hasError && <ErrorText className={errorClass}>{errorMessage}</ErrorText>}
        </div>
    );
};

export default Input;
