import { FC, KeyboardEvent } from 'react';
import { useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';

import { showNavVar } from '@/apollo/reactiveVars';

import { Link } from '@/ui';

import NavItemWithChildren from './NavItemWithChildren';

import { NavItemType } from '../navItems';

import styles from './MobileNav.module.scss';

type Props = {
    handleKeyDown: (event: KeyboardEvent<HTMLAnchorElement>) => void;
} & NavItemType;

const NavItem: FC<Props> = ({ handleKeyDown, title, path, secondaryNav, mainRoot }) => {
    const { formatMessage } = useIntl();
    const showNav = useReactiveVar(showNavVar);

    if (secondaryNav) {
        return (
            <NavItemWithChildren
                handleKeyDown={handleKeyDown}
                title={title}
                secondaryNav={secondaryNav}
                mainRoot={mainRoot}
            />
        );
    }

    return (
        <Link
            href={path || ''}
            onClick={(): boolean => showNavVar(false)}
            onKeyDown={handleKeyDown}
            focusable={showNav}
            className={styles['link']}
            activeClassName={styles['activeLink']}
        >
            <span>{formatMessage(title)}</span>
        </Link>
    );
};

export default NavItem;
