import { useState, useEffect, RefObject } from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

/**
 * Tracks the size and position for a given HTML node.
 */
const useBoundingBox = <T extends HTMLElement>({
    ref,
    scrollDebounce = 60,
    resizeThrottle = 60,
}: {
    ref: RefObject<T>;
    scrollDebounce?: number;
    resizeThrottle?: number;
}): DOMRect | null => {
    const [box, setBox] = useState<DOMRect | null>(null);
    useEffect(() => {
        if (!ref) {
            return;
        }

        // Wait a sec before calculating the initial value.
        window.setTimeout(() => {
            if (ref.current) {
                setBox(ref.current.getBoundingClientRect());
            }
        }, 200);
        function update(): void {
            const newBox = ref.current?.getBoundingClientRect();
            if (newBox) {
                setBox(newBox);
            }
        }
        const handleScroll = debounce(update, scrollDebounce);
        const handleResize = throttle(update, resizeThrottle);
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return box;
};

export default useBoundingBox;
