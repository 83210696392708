import { FC } from 'react';

import { Spinner, Modal } from '@/ui';

import styles from './OverlayLoadingSpinner.module.scss';

const OverlayLoadingSpinner: FC = () => {
    return (
        <Modal isOpen transparent closeOnClick={false} closeButton={false}>
            <div className={styles['wrapper']}>
                <Spinner white size="large" />
            </div>
        </Modal>
    );
};

export default OverlayLoadingSpinner;
