import { FC } from 'react';

type Props = {
    containerClassName?: string;
    white?: boolean;
    size?: 'tiny' | 'small' | 'medium' | 'large';
};

const Spinner: FC<Props> = ({ white = false, size = 'medium' }) => {
    const stroke = white ? '#fff' : '#000';
    let width;

    switch (size) {
        case 'tiny':
            width = 16;
            break;
        case 'small':
            width = 24;
            break;
        case 'medium':
            width = 38;
            break;
        case 'large':
            width = 50;
            break;
    }

    return (
        <svg width={width} height={width} viewBox="0 0 38 38" stroke={stroke}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)" strokeWidth="2">
                    <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"
                        />
                    </path>
                </g>
            </g>
        </svg>
    );
};

export default Spinner;
