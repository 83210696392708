import { FC, useState, ReactNode, KeyboardEvent, useEffect } from 'react';
import classnames from 'classnames';

import ErrorText from '../ErrorText';

import styles from './Checkbox.module.scss';

type Props = {
    checked?: boolean;
    unChecked?: boolean;
    children?: ReactNode;
    disabled?: boolean;
    label?: ReactNode;
    name: string;
    onChange?: (checked: boolean, value: string | number | undefined) => void | null;
    value?: string | number;
    labelClass?: string;
    wrapperClass?: string;
    errorClass?: string;
    errorMessage?: ReactNode;
    hasError?: boolean;
    focusable?: boolean;
};

const Checkbox: FC<Props> = props => {
    const {
        unChecked,
        children,
        disabled,
        label,
        name,
        onChange,
        value,
        labelClass,
        wrapperClass,
        errorClass,
        errorMessage,
        hasError,
        focusable = true,
    } = props;
    const [checked, setChecked] = useState(!!props.checked);

    const handleChange = (): void => {
        if (!disabled) {
            const nextChecked = !checked;
            setChecked(nextChecked);
            if (onChange) {
                onChange(nextChecked, value);
            }
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            handleChange();
        }
    };

    useEffect(() => {
        if (unChecked) {
            setChecked(false);
        }
    }, [unChecked]);

    const wrapperClasses = classnames(styles['wrapper'], wrapperClass);

    const labelContainerClasses = classnames(styles['labelContainer'], {
        [styles['disabled']]: disabled,
    });

    const labelClasses = classnames(styles['label'], labelClass, {
        [styles['disabled']]: disabled,
    });

    const checkboxClasses = classnames(styles['checkbox'], {
        [styles['checked']]: checked,
        [styles['disabled']]: disabled,
        [styles['error']]: hasError,
    });

    const childrenClasses = classnames(styles['children'], {
        [styles['disabled']]: disabled,
    });

    const errorClasses = classnames(styles['errorMessage'], errorClass);

    const id = name?.replace(/\s+/g, '-');

    return (
        <>
            <div className={wrapperClasses}>
                <input
                    className={styles['input']}
                    id={id}
                    name={name}
                    type="checkbox"
                    onChange={handleChange}
                    checked={checked}
                    disabled={disabled}
                    value={value}
                />
                <label className={labelContainerClasses} onClick={handleChange}>
                    <span
                        className={checkboxClasses}
                        tabIndex={focusable ? 0 : -1}
                        onKeyDown={handleKeyDown}
                    >
                        <svg width="18px" height="18px" viewBox="0 0 18 18">
                            <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                            <polyline points="1 9 7 14 15 4"></polyline>
                        </svg>
                    </span>
                    {label && <span className={labelClasses}>{label}</span>}
                </label>
                {children && <div className={childrenClasses}>{children}</div>}
            </div>
            {hasError && <ErrorText className={errorClasses}>{errorMessage}</ErrorText>}
        </>
    );
};

export default Checkbox;
