import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const BCLogo: FC<Props> = ({ className, width = 210, height = 300 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        viewBox="0 0 210 300"
    >
        <rect
            style={{
                strokeWidth: 10,
                fill: 'currentColor',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
            }}
            id="rect43"
            width="10"
            height="233"
            x="3"
            y="10"
        />
        <ellipse
            style={{
                fill: '#000000',
                fillOpacity: 0,
                stroke: 'currentColor',
                strokeWidth: 10,
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 1,
            }}
            cx="74"
            cy="173"
            rx="66"
            ry="68"
        />
        <path
            id="path6151"
            style={{
                fill: 'currentColor',
                fillOpacity: 1,
                stroke: 'currentColor',
                strokeWidth: 10,
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 1,
            }}
            d="M 578.66406 528.69336 A 268.03137 273.23892 0 0 0 310.63281 801.93164 A 268.03137 273.23892 0 0 0 578.66406 1075.1719 A 268.03137 273.23892 0 0 0 778.1875 984.33398 L 778.1875 932.49219 A 236.10584 242.73603 0 0 1 578.99609 1044.998 A 236.10584 242.73603 0 0 1 342.89062 802.26172 A 236.10584 242.73603 0 0 1 578.99609 559.52734 A 236.10584 242.73603 0 0 1 778.1875 673.30664 L 778.1875 619.89844 A 268.03137 273.23892 0 0 0 578.66406 528.69336 z "
            transform="scale(0.26458333)"
        />
    </svg>
);

export default BCLogo;
