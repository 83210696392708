import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
    thick?: boolean;
};

const Plus: FC<Props> = ({ className, width = 64, height = 64, thick = false }) => {
    const strokeWidth = thick ? 3 : 2;
    return (
        <svg
            className={classnames(styles['icon'], className)}
            width={width}
            height={height}
            x="0px"
            y="0px"
            viewBox="0 0 64 64"
            enableBackground="new 0 0 64 64"
        >
            <g>
                <line
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={strokeWidth}
                    strokeMiterlimit="10"
                    x1="32"
                    y1="50"
                    x2="32"
                    y2="14"
                />
                <line
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={strokeWidth}
                    strokeMiterlimit="10"
                    x1="14"
                    y1="32"
                    x2="50"
                    y2="32"
                />
            </g>
        </svg>
    );
};

export default Plus;
