import { RestLink } from 'apollo-link-rest';

export const restLink = new RestLink({
    uri: '/api',
    endpoints: {
        omnivaLocations: '/omniva/get-locations',
        lpExpress: '/lp-express/get-locations',
    },
    customFetch: (uri, config): Promise<Response> =>
        new Promise((resolve, reject) => {
            try {
                const response = fetch(`/api${uri}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: config?.body,
                });
                resolve(response);
            } catch (error) {
                reject({ error, success: false });
            }
        }),
});
