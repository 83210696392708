import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { SUPPORT_EMAIL, SUPPORT_PHONE, SUPPORT_PHONE_PRESENTATIONAL } from '@/data/constants';

import { Container, Link } from '@/ui';

import styles from './Footer.module.scss';

const Footer: FC = () => (
    <footer className={styles['footer']}>
        <Container fullWidth>
            <div className={styles['innerWrapper']}>
                <div className={styles['legal']}>
                    <h3 className={styles['title']}>
                        <FormattedMessage
                            id="components.footer.information"
                            defaultMessage="Informacija"
                        />
                    </h3>
                    <Link
                        className={styles['link']}
                        activeClassName={styles['activeLink']}
                        href={'/shipping/'}
                    >
                        <FormattedMessage
                            id="components.footer.shipping"
                            defaultMessage="Prekių pristatymas"
                        />
                    </Link>
                    <Link
                        className={styles['link']}
                        activeClassName={styles['activeLink']}
                        href={'/payments/'}
                    >
                        <FormattedMessage
                            id="components.footer.payment"
                            defaultMessage="Apmokėjimas"
                        />
                    </Link>
                    <Link
                        className={styles['link']}
                        activeClassName={styles['activeLink']}
                        href={'/returns/'}
                    >
                        <FormattedMessage
                            id="components.footer.returns"
                            defaultMessage="Prekių grąžinimas"
                        />
                    </Link>
                    <Link
                        className={styles['link']}
                        activeClassName={styles['activeLink']}
                        href={'/privacy/'}
                    >
                        <FormattedMessage
                            id="components.footer.privacy"
                            defaultMessage="Privatumo politika"
                        />
                    </Link>
                    <Link
                        className={styles['link']}
                        activeClassName={styles['activeLink']}
                        href={'/terms/'}
                    >
                        <FormattedMessage
                            id="components.footer.terms"
                            defaultMessage="Pirkimo - pardavimo sutartis"
                        />
                    </Link>
                </div>
                <div className={styles['contact']}>
                    <h3 className={styles['title']}>
                        <FormattedMessage
                            id="components.footer.contacts"
                            defaultMessage="Kontaktai"
                        />
                    </h3>
                    <Link className={styles['link']} href={`mailto:${SUPPORT_EMAIL}`}>
                        {SUPPORT_EMAIL}
                    </Link>
                    <div className={styles['text']}>
                        <Link className={styles['link']} href={`tel:${SUPPORT_PHONE}`}>
                            {SUPPORT_PHONE_PRESENTATIONAL}
                        </Link>
                    </div>
                </div>
            </div>
            <div className={classnames(styles['innerWrapper'], styles['copyright'])}>
                Copyright © 2020 Baltic chinchillas
            </div>
        </Container>
    </footer>
);

export default Footer;
