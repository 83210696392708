import { FC, useState, useRef, useEffect } from 'react';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';
import classnames from 'classnames';

import { RemoveCartItem } from '../RemoveCartItem';
import { ShoppingCartButtons } from '../ShoppingCartButtons';

import { removeItemAtIndex, replaceItemAtIndex } from '@/helpers';
import { getItemIndexInCart, getItemQuantityInCart } from '../helpers';

import { ReactiveVar } from '@apollo/client';
import { CartItem } from '@/apollo/reactiveVars';

import styles from './ShoppingCartItem.module.scss';

type Props = {
    productId: string;
    sku: string;
    name: string;
    path: string;
    src: string;
    alt: string;
    price: number;
    discountPrice?: number | null;
    quantity: number;
    size: string;
    productType: string;
    invalidStock: boolean;
    productStock: number;
    cartItems: CartItem[];
    cartItemsVar: ReactiveVar<CartItem[]>;
};

const ShoppingCartItem: FC<Props> = ({
    productId,
    sku,
    path,
    name,
    src,
    alt,
    price,
    discountPrice,
    quantity,
    size,
    productType,
    invalidStock,
    productStock,
    cartItems,
    cartItemsVar,
}) => {
    const [inputDisplay, setInputDisplay] = useState(false);
    const [timeoutID, setTimeoutID] = useState<null | NodeJS.Timeout>(null);
    const elRef = useRef<HTMLDivElement>(null);
    const existingItemIndex = getItemIndexInCart(cartItems, sku);
    const quantityInCart = getItemQuantityInCart(cartItems, sku);
    const itemPrice = discountPrice || price;

    useEffect(() => {
        if (invalidStock) {
            elRef.current?.scrollIntoView();
        }
        return () => {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
        };
    }, [invalidStock, timeoutID]);

    const handleInputQuantity = (input: string): void => {
        if (!input || parseInt(input) <= 0) {
            setInputDisplay(true);
            const timeout = setTimeout(() => {
                setInputDisplay(false);
                const updatedCart = removeItemAtIndex(cartItems, existingItemIndex);
                cartItemsVar(updatedCart);

                const cookieData = JSON.stringify(updatedCart);
                Cookies.set('CART_DATA', cookieData, {
                    expires: 30,
                    sameSite: 'none',
                    secure: true,
                });

                if (
                    process.env.NODE_ENV === 'production' &&
                    Cookies.get('COOKIE_CONSENT_FULL') &&
                    typeof window.gtag !== 'undefined'
                ) {
                    gtag('event', 'remove_from_cart', {
                        currency: 'EUR',
                        items: [
                            {
                                id: sku,
                                name: name,
                                category: productType,
                                price: itemPrice,
                                quantity: quantity,
                                variant: size,
                            },
                        ],
                        value: itemPrice * quantity,
                    });
                }
            }, 1500);
            setTimeoutID(timeout);
        } else {
            if (productStock >= parseInt(input)) {
                if (timeoutID) {
                    clearTimeout(timeoutID);
                }
                setInputDisplay(false);
                const updatedCart = replaceItemAtIndex(cartItems, existingItemIndex, {
                    productId,
                    sku,
                    path,
                    quantity: parseInt(input),
                });
                if (quantity > parseInt(input)) {
                    if (
                        process.env.NODE_ENV === 'production' &&
                        Cookies.get('COOKIE_CONSENT_FULL') &&
                        typeof window.gtag !== 'undefined'
                    ) {
                        gtag('event', 'remove_from_cart', {
                            currency: 'EUR',
                            items: [
                                {
                                    id: sku,
                                    name: name,
                                    category: productType,
                                    price: itemPrice,
                                    quantity: quantity - parseInt(input),
                                    variant: size,
                                },
                            ],
                            value: itemPrice * (quantity - parseInt(input)),
                        });
                    }
                }
                if (quantity < parseInt(input)) {
                    if (
                        process.env.NODE_ENV === 'production' &&
                        Cookies.get('COOKIE_CONSENT_FULL') &&
                        typeof window.gtag !== 'undefined'
                    ) {
                        gtag('event', 'add_to_cart', {
                            currency: 'EUR',
                            items: [
                                {
                                    id: sku,
                                    name: name,
                                    category: productType,
                                    price: itemPrice,
                                    quantity: parseInt(input) - quantity,
                                    variant: size,
                                },
                            ],
                            value: itemPrice * (parseInt(input) - quantity),
                        });
                    }
                }
                cartItemsVar(updatedCart);

                const cookieData = JSON.stringify(updatedCart);
                Cookies.set('CART_DATA', cookieData, {
                    expires: 30,
                    sameSite: 'none',
                    secure: true,
                });
            } else if (productStock < parseInt(input)) {
                if (timeoutID) {
                    clearTimeout(timeoutID);
                }
                setInputDisplay(false);
                const updatedCart = replaceItemAtIndex(cartItems, existingItemIndex, {
                    productId,
                    sku,
                    path,
                    quantity: productStock,
                });
                if (quantity > parseInt(input)) {
                    if (
                        process.env.NODE_ENV === 'production' &&
                        Cookies.get('COOKIE_CONSENT_FULL') &&
                        typeof window.gtag !== 'undefined'
                    ) {
                        gtag('event', 'remove_from_cart', {
                            currency: 'EUR',
                            items: [
                                {
                                    id: sku,
                                    name: name,
                                    category: productType,
                                    price: itemPrice,
                                    quantity: quantity - productStock,
                                    variant: size,
                                },
                            ],
                            value: itemPrice * (quantity - productStock),
                        });
                    }
                }
                if (quantity < parseInt(input)) {
                    if (
                        process.env.NODE_ENV === 'production' &&
                        Cookies.get('COOKIE_CONSENT_FULL') &&
                        typeof window.gtag !== 'undefined'
                    ) {
                        gtag('event', 'add_to_cart', {
                            currency: 'EUR',
                            items: [
                                {
                                    id: sku,
                                    name: name,
                                    category: productType,
                                    price: itemPrice,
                                    quantity: productStock - quantity,
                                    variant: size,
                                },
                            ],
                            value: itemPrice * (productStock - quantity),
                        });
                    }
                }
                cartItemsVar(updatedCart);

                const cookieData = JSON.stringify(updatedCart);
                Cookies.set('CART_DATA', cookieData, {
                    expires: 30,
                    sameSite: 'none',
                    secure: true,
                });
            }
        }
    };

    const decrementQuantity = (): void => {
        if (quantity > 1) {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
            setInputDisplay(false);
            const updatedCart = replaceItemAtIndex(cartItems, existingItemIndex, {
                productId,
                sku,
                path,
                quantity: quantity - 1,
            });
            cartItemsVar(updatedCart);

            const cookieData = JSON.stringify(updatedCart);
            Cookies.set('CART_DATA', cookieData, { expires: 30, sameSite: 'none', secure: true });

            if (
                process.env.NODE_ENV === 'production' &&
                Cookies.get('COOKIE_CONSENT_FULL') &&
                typeof window.gtag !== 'undefined'
            ) {
                gtag('event', 'remove_from_cart', {
                    currency: 'EUR',
                    items: [
                        {
                            id: sku,
                            name: name,
                            category: productType,
                            price: itemPrice,
                            quantity: 1,
                            variant: size,
                        },
                    ],
                    value: itemPrice,
                });
            }
        }
    };

    const incrementQuantity = (): void => {
        if (productStock <= quantityInCart) {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
            setInputDisplay(false);
            return;
        } else {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
            setInputDisplay(false);
            const updatedCart = replaceItemAtIndex(cartItems, existingItemIndex, {
                productId,
                sku,
                path,
                quantity: quantity + 1,
            });
            cartItemsVar(updatedCart);

            const cookieData = JSON.stringify(updatedCart);
            Cookies.set('CART_DATA', cookieData, { expires: 30, sameSite: 'none', secure: true });

            if (
                process.env.NODE_ENV === 'production' &&
                Cookies.get('COOKIE_CONSENT_FULL') &&
                typeof window.gtag !== 'undefined'
            ) {
                gtag('event', 'add_to_cart', {
                    currency: 'EUR',
                    items: [
                        {
                            id: sku,
                            name: name,
                            category: productType,
                            price: price,
                            quantity: 1,
                            variant: size,
                        },
                    ],
                    value: itemPrice,
                });
            }
        }
    };

    return (
        <div className={styles['wrapper']} ref={elRef}>
            <div className={styles['imageWrapper']}>
                <Image src={src} alt={alt} layout="fill" objectFit="contain" />
            </div>
            <div className={styles['description']}>
                <div className={styles['title']}>
                    <div className={styles['name']}>{name}</div>
                    <div className={styles['size']}>{size}</div>
                </div>
                <div className={styles['removeWrapper']}>
                    <RemoveCartItem
                        index={existingItemIndex}
                        cartItems={cartItems}
                        cartItemsVar={cartItemsVar}
                        // For GTAG
                        sku={sku}
                        name={name}
                        productType={productType}
                        price={itemPrice}
                        size={size}
                        quantity={quantity}
                    />
                </div>
                <div className={styles['price']}>
                    <div className={styles['priceLabel']}>
                        <FormattedMessage
                            id="components.shoppingCart.shoppingCartItem.price"
                            defaultMessage="Kaina"
                        />
                    </div>
                    {discountPrice && (
                        <div
                            className={classnames({
                                [styles['with-discount']]: discountPrice,
                            })}
                        >
                            {discountPrice.toFixed(2)} €
                        </div>
                    )}
                    <div
                        className={classnames({
                            [styles['priceText']]: !discountPrice,
                            [styles['has-discount']]: discountPrice,
                        })}
                    >
                        {price.toFixed(2)} €
                    </div>
                </div>
                <div className={styles['buttonsWrapper']}>
                    <div className={styles['quantityLabel']}>
                        <FormattedMessage
                            id="components.shoppingCart.shoppingCartItem.quantity"
                            defaultMessage="Kiekis"
                        />
                    </div>
                    <div>
                        <ShoppingCartButtons
                            quantity={quantity}
                            productStock={productStock}
                            decrementQuantity={decrementQuantity}
                            incrementQuantity={incrementQuantity}
                            handleInputQuantity={handleInputQuantity}
                            inputDisplay={inputDisplay}
                        />
                        {invalidStock && (
                            <div className={styles['errorText']}>
                                <FormattedMessage
                                    id="components.shoppingCart.shoppingCartItem.amountError"
                                    defaultMessage="Likutis {amount} vnt."
                                    values={{
                                        amount: productStock,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles['totalPrice']}>
                    <div>
                        <div className={styles['priceLabel']}>
                            <FormattedMessage
                                id="components.shoppingCart.shoppingCartItem.sum"
                                defaultMessage="Suma"
                            />
                        </div>
                        <div className={styles['priceText']}>
                            {(itemPrice * quantity).toFixed(2)} €
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShoppingCartItem;
