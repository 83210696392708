import { asTypename } from '@/crystallize/helpers';

import { ProductFragment } from '@/catalogueFragments';
import { CartItem } from '@/apollo/reactiveVars';

type ProductVariant = NonNullable<ProductFragment['variants']>[number];

export default function getShoppingCartProductVariantData(
    cartItem: CartItem,
    shoppingCartItemData: ProductFragment[]
): ProductVariant | null {
    const product = shoppingCartItemData.find(p => p?.id === cartItem.productId);
    const { variants } = asTypename(product, 'Product') || {};
    return (variants || []).find(v => v.sku === cartItem.sku) || null;
}
