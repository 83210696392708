import { ChangeEvent, FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import classnames from 'classnames';

import { showCartVar } from '@/apollo/reactiveVars';

import { Button } from '@/ui';
import { Minus, Plus } from '@/components/Icons';

import styles from './ShoppingCartButtons.module.scss';

type Props = {
    quantity: number;
    productStock: number;
    decrementQuantity: () => void;
    incrementQuantity: () => void;
    handleInputQuantity: (input: string) => void;
    inputDisplay: boolean;
};

const ShoppingCartButtons: FC<Props> = ({
    quantity,
    productStock,
    decrementQuantity,
    incrementQuantity,
    handleInputQuantity,
    inputDisplay,
}) => {
    const showCart = useReactiveVar(showCartVar);

    return (
        <div className={styles['wrapper']}>
            <div className={styles['buttonWrapper']}>
                <Button
                    className={styles['button']}
                    variant="fill"
                    onClick={(): void => decrementQuantity()}
                    focusable={showCart}
                >
                    <Minus width={25} height={25} thick />
                </Button>
            </div>
            <input
                tabIndex={showCart ? 0 : -1}
                type="number"
                className={styles['input']}
                value={inputDisplay ? '' : quantity}
                name="itemQuantity"
                min={1}
                pattern="[0-9]*"
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                    handleInputQuantity(event.target.value);
                }}
            />
            <div
                className={classnames(styles['buttonWrapper'], {
                    [styles['hidden']]: quantity >= productStock,
                })}
            >
                <Button
                    className={styles['button']}
                    variant="fill"
                    onClick={(): void => incrementQuantity()}
                    focusable={showCart}
                >
                    <Plus width={25} height={25} thick />
                </Button>
            </div>
        </div>
    );
};

export default ShoppingCartButtons;
