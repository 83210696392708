import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const NumberFour: FC<Props> = ({ className, width = 640, height = 640 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640"
    >
        <defs>
            <path
                d="M0 320C0 496 144 640 320 640C496 640 640 496 640 320C640 144 496 0 320 0C144 0 0 144 0 320ZM360 173L360 368L400 368L400 398.5L360 398.5L360 467L326.5 467L326.5 398.5L197 398.5L197 365L331 173L360 173ZM229 368L328 368L328 222.5L326.5 222.5L229 368Z"
                id="b95pP7Ko"
            />
        </defs>
        <g>
            <g>
                <use xlinkHref="#b95pP7Ko" opacity="1" fill="currentColor" fillOpacity="1" />
                <g>
                    <use
                        xlinkHref="#b95pP7Ko"
                        opacity="1"
                        fillOpacity="0"
                        stroke="currentColor"
                        strokeWidth="1"
                        strokeOpacity="0"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default NumberFour;
