import { FC, useState, KeyboardEvent } from 'react';
import { useRouter } from 'next/router';
import { useIntl, MessageDescriptor } from 'react-intl';
import classnames from 'classnames';

import { ChevronDownIcon } from '@/components/Icons';
import { Link } from '@/ui';

import styles from './DesktopNav.module.scss';

type Props = {
    title: MessageDescriptor;
    secondaryNav: {
        title: MessageDescriptor;
        path: string;
    }[];
    showCart: boolean;
    mainRoot?: boolean;
};

const NavItemWithChildren: FC<Props> = props => {
    const router = useRouter();
    const { formatMessage } = useIntl();
    const [showSecondaryNav, setShowSecondaryNav] = useState(false);
    const hasPath = props.secondaryNav.some(item =>
        item.path.includes(router.asPath.split('?')[0])
    );
    const isMainRoot = router.asPath === '/';
    const showActiveLink = isMainRoot && props.mainRoot ? hasPath : hasPath && !isMainRoot;

    const handleKeyDown = (event: KeyboardEvent<HTMLAnchorElement>): void => {
        if (event.key === ' ' || event.key === 'Enter') {
            setShowSecondaryNav(false);
        }
    };

    return (
        <div
            className={classnames(styles['navItemWithChildren'], {
                [styles['expanded']]: showSecondaryNav,
            })}
            onFocus={(): void => setShowSecondaryNav(true)}
            onBlur={(): void => setShowSecondaryNav(false)}
            onMouseEnter={(): void => setShowSecondaryNav(true)}
            onMouseLeave={(): void => setShowSecondaryNav(false)}
        >
            <Link
                className={classnames(styles['link'], {
                    [styles['activeLink']]: showActiveLink,
                })}
                focusable={!props.showCart}
            >
                <span className={styles['linkInnerWrapper']}>
                    {formatMessage(props.title)}
                    <ChevronDownIcon className={styles['icon']} />
                </span>
            </Link>
            <div className={styles['secondaryNav']}>
                {props.secondaryNav.map(({ title, path }, idx) => (
                    <Link
                        key={`${path}-${idx}`}
                        href={path}
                        className={styles['link']}
                        activeClassName={styles['activeLink']}
                        onKeyDown={handleKeyDown}
                        focusable={!props.showCart}
                        onClick={(): void => {
                            setShowSecondaryNav(!showSecondaryNav);
                        }}
                    >
                        {formatMessage(title)}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default NavItemWithChildren;
