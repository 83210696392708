import { useMemo } from 'react';
import { GetServerSidePropsContext } from 'next';
import { IncomingMessage } from 'http';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import isEqual from 'lodash/isEqual';
import merge from 'deepmerge';
import cookie from 'cookie';

import { IS_DEVELOPMENT, IS_PREVIEW } from '@/data/constants';

import { link } from './link';
import { cache } from './cache';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageProps = any;

export const APOLLO_STATE_PROPERTY_NAME = '__APOLLO_STATE__';
export const COOKIES_TOKEN_NAME = 'jwt';

const getToken = (req?: IncomingMessage): string => {
    // TODO: fix document undefined
    const parsedCookie = cookie.parse(req ? req.headers.cookie ?? '' : document.cookie);

    return parsedCookie[COOKIES_TOKEN_NAME];
};

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const createApolloClient = (
    ctx?: GetServerSidePropsContext | null
): ApolloClient<NormalizedCacheObject> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const authLink = setContext((_, { headers }) => {
        // Get the authentication token from cookies
        const token = getToken(ctx?.req);

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    });

    return new ApolloClient({
        ssrMode: typeof window === 'undefined',
        // link: authLink.concat(link),
        link,
        cache,
        connectToDevTools: IS_DEVELOPMENT || IS_PREVIEW,
    });
};

export function initializeApollo(
    initialState = null,
    ctx = null
): ApolloClient<NormalizedCacheObject> {
    const client = apolloClient ?? createApolloClient(ctx);

    // If your page has Next.js data fetching methods that use Apollo Client,
    // the initial state gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = client.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(existingCache, initialState, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
            ],
        });

        // Restore the cache with the merged data
        client.cache.restore(data);
    }

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') {
        return client;
    }

    // Create the Apollo Client once in the client
    if (!apolloClient) {
        apolloClient = client;
    }

    return client;
}

export function addApolloState(
    client: ApolloClient<NormalizedCacheObject>,
    pageProps: PageProps
): PageProps {
    if (pageProps?.props) {
        pageProps.props[APOLLO_STATE_PROPERTY_NAME] = client.cache.extract();
    }

    return pageProps;
}

export function useApollo(pageProps: PageProps): ApolloClient<NormalizedCacheObject> {
    const state = pageProps[APOLLO_STATE_PROPERTY_NAME];
    const store = useMemo(() => initializeApollo(state), [state]);

    return store;
}
