import { FC, ChangeEvent, ReactNode, KeyboardEvent } from 'react';
import classnames from 'classnames';

import styles from './Radio.module.scss';

type RadioValue = string | number;

type Props = {
    checked: boolean;
    children?: ReactNode;
    disabled?: boolean;
    inputId?: string;
    label?: ReactNode;
    name: string;
    value: string | number;
    type?: 'default';
    onChange: (
        value?: RadioValue,
        e?: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLDivElement>
    ) => void | null;
    focusable?: boolean;
    hasError?: boolean;
    labelClass?: string;
    wrapperClass?: string;
    radioClass?: string;
    childrenClass?: string;
};

const Radio: FC<Props> = props => {
    const {
        checked,
        children,
        disabled,
        inputId,
        label,
        name,
        value,
        onChange,
        focusable = true,
        labelClass,
        wrapperClass,
        radioClass,
        childrenClass,
    } = props;

    const wrapperClasses = classnames(styles['wrapper'], wrapperClass);

    const labelContainerClasses = classnames(styles['labelContainer'], {
        [styles['disabled']]: disabled,
    });
    const radioClasses = classnames(styles['radio'], radioClass, {
        [styles['disabled']]: disabled,
        [styles['checked']]: checked,
    });
    const labelClasses = classnames(styles['label'], labelClass, {
        [styles['disabled']]: disabled,
    });
    const childrenClasses = classnames(styles['children'], childrenClass, {
        [styles['disabled']]: disabled,
    });

    const id = (inputId ? inputId : `${name}-${value.toString()}`).replace(/\s+/g, '-');

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            if (!disabled) {
                onChange(value, e);
            }
        }
    };

    return (
        <div className={wrapperClasses}>
            <input
                id={id}
                name={name}
                onChange={(e): void | null => onChange(value, e)}
                type="radio"
                value={value}
                checked={checked}
                disabled={disabled}
                className={styles['input']}
                tabIndex={0}
            />
            <label className={labelContainerClasses} htmlFor={id}>
                <span
                    tabIndex={focusable ? 0 : -1}
                    className={radioClasses}
                    onKeyDown={handleKeyDown}
                />
                {label && <span className={labelClasses}>{label}</span>}
            </label>
            {children && <div className={childrenClasses}>{children}</div>}
        </div>
    );
};

export default Radio;
