/**
 * Narrows union type by __typename
 * @param o Value
 * @param v __typename
 * @returns Narrowed value | null
 */
export default function asTypename<
    T extends { __typename?: string },
    V extends string & T['__typename']
>(o: T | null | undefined, v: V): Extract<T, Record<'__typename', V>> | null {
    return o && o['__typename'] === v ? (o as Extract<T, Record<'__typename', V>>) : null;
}
