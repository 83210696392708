import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
    thick?: boolean;
};

const Minus: FC<Props> = ({ className, width = 64, height = 64, thick = false }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        enableBackground="new 0 0 64 64"
    >
        <g>
            <line
                fill="none"
                stroke="currentColor"
                strokeWidth={thick ? 3 : 2}
                strokeMiterlimit="10"
                x1="14"
                y1="31"
                x2="50"
                y2="31"
            />
        </g>
    </svg>
);

export default Minus;
