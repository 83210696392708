import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const CheckedRound: FC<Props> = ({ className, width = 640, height = 640 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640"
    >
        <path
            d="M640 320C640 496.61 496.61 640 320 640C143.39 640 0 496.61 0 320C0 143.39 143.39 0 320 0C496.61 0 640 143.39 640 320Z"
            id="c1frOUYkAh"
        ></path>
        <path d="M471.46 220.08L280.9 409.99L161.89 293.82" id="c2969upnt5"></path>
        <use xlinkHref="#c1frOUYkAh" opacity="1" fill="#333" fillOpacity="1"></use>
        <use
            xlinkHref="#c1frOUYkAh"
            opacity="1"
            fillOpacity="0"
            stroke="#333"
            strokeWidth="1"
            strokeOpacity="1"
        ></use>
        <use
            xlinkHref="#c2969upnt5"
            opacity="1"
            fillOpacity="0"
            stroke="#ffffff"
            strokeWidth="50"
            strokeOpacity="1"
        ></use>
    </svg>
);

export default CheckedRound;
