import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const PayseraLogo: FC<Props> = ({ className, width = 563, height = 130 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        viewBox="0 0 563 130"
        fill="none"
    >
        <g clipPath="url(#clip0_210_854)">
            <path d="M22.3631 50.6808H0V129.985H22.3631V50.6808Z" fill="#33CC66" />
            <path
                d="M79.3 50.6808C79.3455 58.2014 76.3789 65.4339 71.0487 70.7975C65.7185 76.1611 58.4582 79.2196 50.8548 79.3044V79.3044C37.328 79.3934 24.3866 84.7737 14.8578 94.2698C5.32904 103.766 -0.0125446 116.606 2.21234e-05 129.985H22.3631C22.3299 122.463 25.3047 115.233 30.6389 109.871C35.9732 104.509 43.2343 101.45 50.8393 101.361C64.3645 101.276 77.3051 95.8964 86.8298 86.3986C96.3546 76.9007 101.688 64.0582 101.663 50.6808H79.3Z"
                fill="#000099"
            />
            <path
                d="M50.8395 -0.0305176C37.3156 0.0584791 24.3773 5.43974 14.8535 14.9367C5.32965 24.4338 -0.00506811 37.2739 0.0156853 50.65H22.3633C22.322 43.1281 25.292 35.8956 30.6246 30.5324C35.9573 25.1693 43.2192 22.1113 50.824 22.0265C58.4302 22.1073 65.6944 25.164 71.0279 30.528C76.3615 35.8921 79.3302 43.1269 79.2848 50.65H101.648C101.673 37.2753 96.3414 24.4351 86.8201 14.9377C77.2987 5.44024 64.362 0.0584899 50.8395 -0.0305176V-0.0305176Z"
                fill="#3399FF"
            />
            <path
                d="M389.752 44.512L381.581 54.3696C378.052 50.8503 373.267 48.8437 368.256 48.7826C364.062 48.7826 361.895 50.2061 361.895 52.946C361.895 55.1961 363.443 56.39 368.086 58.1656L373.858 60.5382C385.373 65.1302 390.542 69.7987 390.542 78.4777C390.66 81.1329 390.12 83.7765 388.968 86.1778C387.816 88.5791 386.088 90.6651 383.933 92.2537C379.145 95.7257 373.306 97.4852 367.374 97.2437C356.804 97.2437 348.648 93.4476 342.767 85.8402L350.923 76.1051C356.092 81.4471 361.756 84.0646 367.606 84.0646C371.924 84.0646 375.174 82.3962 375.174 79.1971C375.174 76.7021 373.487 75.753 368.983 73.855L362.623 71.2376C351.696 66.8445 346.419 61.977 346.419 53.3134C346.419 42.2773 355.302 35.6188 368.271 35.6188C377.232 35.6035 384.336 38.573 389.752 44.512Z"
                fill="#000099"
            />
            <path
                d="M272.505 37.5016H257.617V43.8539C249.399 33.7209 228.924 32.7565 217.781 44.757C207.149 56.1911 206.638 76.9011 217.781 88.8863C228.924 100.871 249.383 99.9071 257.601 89.7741V96.1264H272.489L272.505 37.5016ZM241.552 82.9472C221.077 82.9472 221.046 50.6807 241.552 50.6807C261.517 50.6807 261.594 82.9472 241.552 82.9472Z"
                fill="#000099"
            />
            <path
                d="M139.502 114.448H154.375V89.82C162.608 99.953 183.083 100.917 194.211 88.9321C205.338 76.947 204.843 56.237 194.211 44.7876C183.068 32.8024 162.593 33.7667 154.375 43.8998V37.5475H139.518L139.502 114.448ZM170.455 50.7266C190.945 50.7266 190.914 82.9931 170.455 82.9931C150.413 82.9931 150.506 50.7266 170.486 50.7266H170.455Z"
                fill="#000099"
            />
            <path
                d="M562.219 37.1955H547.331V43.5478C539.098 33.4147 518.638 32.4504 507.495 44.4509C496.863 55.9003 496.353 76.595 507.495 88.5802C518.638 100.565 539.098 99.6163 547.316 89.4679V95.8202H562.204L562.219 37.1955ZM531.267 82.6411C510.807 82.6411 510.776 50.3745 531.267 50.3745C551.231 50.3745 551.308 82.6258 531.251 82.6258L531.267 82.6411Z"
                fill="#000099"
            />
            <path
                d="M326.857 37.0883L311.381 75.6765L296.849 37.0883H279.732L302.482 92.4221C301.771 96.3712 298.567 100.764 288.012 100.917V114.77C301.724 115.704 311.985 108.739 316.813 97.7948L343.912 37.0424L326.857 37.0883Z"
                fill="#000099"
            />
            <path
                d="M494.619 51.6143V36.3076C492.959 36.0608 491.283 35.9329 489.605 35.9249C486.022 35.8165 482.455 36.4235 479.115 37.7096C475.775 38.9956 472.732 40.9344 470.167 43.4099C467.565 46.024 465.536 49.1397 464.204 52.5631C462.873 55.9865 462.269 59.6442 462.428 63.3087V96.2028H478.648V62.528C478.573 61.0502 478.806 59.573 479.333 58.1879C479.859 56.8029 480.668 55.5396 481.708 54.4762C482.748 53.4128 483.998 52.5721 485.381 52.0061C486.763 51.4401 488.248 51.1608 489.744 51.1857C491.378 51.2028 493.008 51.3461 494.619 51.6143Z"
                fill="#000099"
            />
            <path
                d="M426.648 35.6954C420.477 35.7068 414.448 37.5305 409.327 40.9352C404.205 44.3399 400.222 49.1722 397.881 54.8192C395.54 60.4663 394.947 66.6737 396.178 72.6543C397.409 78.6349 400.408 84.1192 404.795 88.4117C417.764 100.734 440.019 99.3254 451.394 89.9424L445.203 78.5389C436.692 85.0136 416.046 87.279 411.806 71.758H454.257C454.659 65.8496 455.681 57.3238 451.162 49.2878C443.671 35.7872 429.743 35.6954 426.648 35.6954ZM426.044 48.6448C432.869 48.6448 438.317 52.9767 438.812 59.7729H412.022C414.112 52.9767 419.451 48.6448 426.044 48.6448V48.6448Z"
                fill="#000099"
            />
        </g>
        <defs>
            <clipPath id="clip0_210_854">
                <rect width="562.219" height="130" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default PayseraLogo;
