import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const Close: FC<Props> = ({ className, width = 26, height = 26 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        viewBox="0 0 64 64"
    >
        <line
            x1="9.37"
            x2="54.63"
            y1="9.37"
            y2="54.63"
            fill="none"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeWidth="4"
        />
        <line
            x1="9.37"
            x2="54.63"
            y1="54.63"
            y2="9.37"
            fill="none"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeWidth="4"
        />
    </svg>
);

export default Close;
