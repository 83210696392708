import { FC, Fragment } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Button } from '@/ui';

import styles from './LanguageSwitcher.module.scss';

const LanguageSwitcher: FC = () => {
    const router = useRouter();
    const { pathname, asPath, query, locales } = router;

    if (!Array.isArray(locales)) {
        return null;
    }

    return (
        <div className={styles['wrapper']}>
            {locales.map((locale, idx) => (
                <Fragment key={locale}>
                    <Button
                        onClick={(): void => {
                            /**
                             * Change just the locae and maintain all other
                             * route information including href's query
                             */
                            router.push({ pathname, query }, asPath, { locale });
                        }}
                    >
                        <span
                            className={classnames(styles['locale'], {
                                [styles['isActive']]: locale === router.locale,
                            })}
                        >
                            {locale}
                        </span>
                    </Button>
                    {idx < locales.length - 1 && <span> | </span>}
                </Fragment>
            ))}
        </div>
    );
};

export default LanguageSwitcher;
