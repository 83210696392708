import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const ChevronLeftRoundFilled: FC<Props> = ({ className, width = 640, height = 640 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640"
    >
        <defs>
            <path
                d="M640 320C640 496.61 496.61 640 320 640C143.39 640 0 496.61 0 320C0 143.39 143.39 0 320 0C496.61 0 640 143.39 640 320Z"
                id="cEPd99CUH"
            ></path>
            <path d="M379.99 474.62L224.99 319.62L379.98 164.62" id="a3kGh8a3sT"></path>
        </defs>
        <g>
            <g>
                <g>
                    <use
                        xlinkHref="#cEPd99CUH"
                        opacity="1"
                        fill="currentColor"
                        fillOpacity="1"
                    ></use>
                </g>
                <g>
                    <g>
                        <use
                            xlinkHref="#a3kGh8a3sT"
                            opacity="1"
                            fillOpacity="0"
                            stroke="#ffffff"
                            strokeWidth="50"
                            strokeOpacity="1"
                        ></use>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default ChevronLeftRoundFilled;
