import { FC } from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

type Props = {
    className?: string;
    width?: number;
    height?: number;
};

const NumberThree: FC<Props> = ({ className, width = 640, height = 640 }) => (
    <svg
        className={classnames(styles['icon'], className)}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640"
    >
        <defs>
            <path
                d="M0 320C0 496 144 640 320 640C496 640 640 496 640 320C640 144 496 0 320 0C144 0 0 144 0 320ZM224 371.2C227.52 371.2 255.68 371.2 259.2 371.2C259.2 419.2 292.8 436.8 321.6 436.8C329.6 436.8 385.6 432 385.6 379.2C385.6 324.8 331.2 323.2 323.2 323.2C315.2 323.2 308.8 323.2 302.4 324.8C302.4 321.76 302.4 297.44 302.4 294.4C304 294.4 318.4 294.4 318.4 294.4C369.6 294.4 374.4 254.4 374.4 246.4C374.4 230.4 368 198.4 321.6 198.4C268.8 198.4 265.6 254.4 265.6 262.4C262.08 262.4 233.92 262.4 230.4 262.4C230.4 248 235.2 166.4 321.6 166.4C408 166.4 411.2 232 411.2 244.8C411.2 278.4 382.4 297.6 371.2 304C371.2 304.16 371.2 305.44 371.2 305.6C388.8 308.8 424 324.8 424 377.6C422.4 462.4 339.2 467.2 321.6 467.2C228.8 467.2 224 392 224 371.2Z"
                id="a2kYdgRz9D"
            ></path>
        </defs>
        <g>
            <g>
                <use xlinkHref="#a2kYdgRz9D" opacity="1" fill="currentColor" fillOpacity="1"></use>
                <g>
                    <use
                        xlinkHref="#a2kYdgRz9D"
                        opacity="1"
                        fillOpacity="0"
                        stroke="currentColor"
                        strokeWidth="1"
                        strokeOpacity="0"
                    ></use>
                </g>
            </g>
        </g>
    </svg>
);

export default NumberThree;
